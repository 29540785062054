import React, { useState } from 'react';
import '../../TeleHealth/TeleHealth.css';

const CreateDoctor = () => {
  const [formData, setFormData] = useState({
    name: '',
    bio: '',
    phoneNumber: '',
    email: '',
    isOfflineConsultationAvailable: false,
    isOnlineConsultationAvailable: false,
    consultationFee: '',
    gender: '',
    age: '',
    specializations: [{ id: '', experience: '' }],
    qualifications: [{ id: '', college: '', location: '', fromYear: '', toYear: '' }],
    clinics: [],
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleArrayInputChange = (e, arrayName, index) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [arrayName]: [
        ...prevState[arrayName].slice(0, index),
        {
          ...prevState[arrayName][index],
          [name.split('.')[1]]: value
        },
        ...prevState[arrayName].slice(index + 1)
      ]
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
  };

  const handleReset = () => {
    setFormData({
      name: '',
      bio: '',
      phoneNumber: '',
      email: '',
      isOfflineConsultationAvailable: false,
      isOnlineConsultationAvailable: false,
      consultationFee: '',
      gender: '',
      age: '',
      specializations: [{ id: '', experience: '' }],
      qualifications: [{ id: '', college: '', location: '', fromYear: '', toYear: '' }],
      clinics: [],
    });
  };

  return (
    <form className="form-container-doctor" onSubmit={handleSubmit} onReset={handleReset}>
      <div className="form-group">
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Enter Name"
        />
      </div>

      <div className="form-group">
        <label htmlFor="bio">Bio:</label>
        <textarea
          id="bio"
          name="bio"
          value={formData.bio}
          onChange={handleInputChange}
          placeholder="Enter Bio"
        />
      </div>

      <div className="form-group">
        <label htmlFor="phoneNumber">Phone Number:</label>
        <input
          type="tel"
          id="phoneNumber"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleInputChange}
          placeholder="Enter Phone Number"
        />
      </div>

      <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Enter Email"
        />
      </div>

      <div className="form-group">
        <input
          type="checkbox"
          id="isOfflineConsultationAvailable"
          name="isOfflineConsultationAvailable"
          checked={formData.isOfflineConsultationAvailable}
          onChange={handleInputChange}
        />
        <label htmlFor="isOfflineConsultationAvailable">Offline Consultation Available</label>
      </div>

      <div className="form-group">
        <input
          type="checkbox"
          id="isOnlineConsultationAvailable"
          name="isOnlineConsultationAvailable"
          checked={formData.isOnlineConsultationAvailable}
          onChange={handleInputChange}
        />
        <label htmlFor="isOnlineConsultationAvailable">Online Consultation Available</label>
      </div>

      <div className="form-group">
        <label htmlFor="consultationFee">Consultation Fee:</label>
        <input
          type="text"
          id="consultationFee"
          name="consultationFee"
          value={formData.consultationFee}
          onChange={handleInputChange}
          placeholder="Enter Consultation Fee"
        />
      </div>

      <div className="form-group">
        <label htmlFor="gender">Gender:</label>
        <div className="radio-group">
          <input
            type="radio"
            id="male"
            name="gender"
            value="male"
            checked={formData.gender === "male"}
            onChange={handleInputChange}
          />
          <label htmlFor="male">Male</label>

          <input
            type="radio"
            id="female"
            name="gender"
            value="female"
            checked={formData.gender === "female"}
            onChange={handleInputChange}
          />
          <label htmlFor="female">Female</label>

          <input
            type="radio"
            id="other"
            name="gender"
            value="other"
            checked={formData.gender === "other"}
            onChange={handleInputChange}
          />
          <label htmlFor="other">Other</label>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="age">Age:</label>
        <input
          type="number"
          id="age"
          name="age"
          value={formData.age}
          onChange={handleInputChange}
          placeholder="Enter Age"
        />
      </div>

      <div className="form-group">
        <label>Specializations:</label>
        {formData.specializations.map((specialization, index) => (
          <div key={index} className="array-input">
            <input
              type="text"
              name={`specializations[${index}].id`}
              value={specialization.id}
              onChange={(e) => handleArrayInputChange(e, 'specializations', index)}
              placeholder="Specialization ID"
            />
            <input
              type="text"
              name={`specializations[${index}].experience`}
              value={specialization.experience}
              onChange={(e) => handleArrayInputChange(e, 'specializations', index)}
              placeholder="Experience"
            />
          </div>
        ))}
        <button type="button" onClick={() => setFormData(prevState => ({
          ...prevState,
          specializations: [...prevState.specializations, { id: '', experience: '' }]
        }))}>Add Specialization</button>
      </div>

      <div className="form-group">
        <label>Qualifications:</label>
        {formData.qualifications.map((qualification, index) => (
          <div key={index} className="array-input">
            <input
              type="text"
              name={`qualifications[${index}].id`}
              value={qualification.id}
              onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
              placeholder="Qualification ID"
            />
            <input
              type="text"
              name={`qualifications[${index}].college`}
              value={qualification.college}
              onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
              placeholder="College"
            />
            <input
              type="text"
              name={`qualifications[${index}].location`}
              value={qualification.location}
              onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
              placeholder="Location"
            />
            <input
              type="text"
              name={`qualifications[${index}].fromYear`}
              value={qualification.fromYear}
              onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
              placeholder="From Year"
            />
            <input
              type="text"
              name={`qualifications[${index}].toYear`}
              value={qualification.toYear}
              onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
              placeholder="To Year"
            />
          </div>
        ))}
        <button className='submitBtn' type="button" onClick={() => setFormData(prevState => ({
          ...prevState,
          qualifications: [...prevState.qualifications, { id: '', college: '', location: '', fromYear: '', toYear: '' }]
        }))}>Add Qualification</button>
      </div>

      <button className='submitBtn' type="submit">Submit</button>
      <button className='resetBtn' type="reset">Reset</button>
    </form>
  );
};

export default CreateDoctor;