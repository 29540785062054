import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../AdminPanel/style.css';
import { Link } from '@mui/material';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { dashboardUsers } from "../../AdminPanel/data/data.js";
import Sidebar from '../../AdminPanel/SideBar/Sidebar.tsx';
import Navbar from '../../AdminPanel/Navbar/Navbar.tsx';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
 import CreateDistrict from './CreateDistrict.js';
 import UpdateDistrict from './UpdateDistrict.js';
import { Tag } from 'primereact/tag';


export default function DistrictsList() {
	const UAToken = localStorage.getItem("accessToken");
	const navigate = useNavigate();

	const [districtsList, setDistrictsList] = useState([]);
	const [showEditMode, setShowEditMode] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState(null);
	const [isActive, setIsActive] = useState(false);
	const [data, setData] = useState([]);
	const [selectedRowItem, setSelectedRowItem] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);

	const [showCreateForm, setShowCreateForm] = useState(false); // State for showing create form
	const [getReloadData, setGetReloadData] = useState(false); 
	const apiEndPoint = 'https://ciaitelehealth.com/api';



	const getSeverity = (status) => {
		switch (status) {
			case 'Active':
				return 'active-status';
			case 'In-Active':
				return 'In-active-status';
		}
	};

	useEffect(() => {
		setDistrictsList(dashboardUsers)
	}, []);

	useEffect(() => {
		const token = localStorage.getItem('accessToken');
		if (!!token) {
			getDistrictsList();
		}
	}, []);

	useEffect(() => {
		if (UAToken && UAToken !== "" && UAToken !== null) {
			navigate('/districtsList');
		}
		else {
			navigate('/')
		}
	}, [UAToken]);

	

	const getDistrictsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');

			const response = await fetch(`${apiEndPoint}/districts/get-districts-list`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				//localStorage.setItem("accessToken","");
				//navigate('/login');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setData(actualData.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	useEffect(() => {
		if (getReloadData) {
			getDistrictsList();
			setGetReloadData(false);
		}
	}, [getReloadData])

	console.log('data',data)


	const deleteConfirm = (slotId) => {
		confirmDialog({
			message: 'Are you sure you want to delete this districts?',
			header: 'Confirmation',
			accept: () => deleteDistrict(slotId),
		});
	}
	const handleChange = () => {
		setIsActive(!isActive);
	}

	const deleteDistrict = async (Id) => {
		try {
			const token = localStorage.getItem('accessToken');
			await fetch(`${apiEndPoint}/districts/delete-district/${Id}`, {
				method: 'DELETE',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			})
				.then(response => response.json())
				.then(actualData => {
					console.log('actualData ', actualData);
					toast.success("district Record Deleted Successfully.");
					getDistrictsList();
				})
				.catch(err => {
					console.log(err.message);
				});
		}
		catch (e) {
			console.log(e)
		}
	}

	const actionBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<Button  icon="pi pi-pencil" title='Edit' rounded outlined className="mr-2 editBtnFocus" onClick={() => {
					setSelectedRowItem(rowData)
					setShowEditMode(true)
				}} />
				<Button icon="pi pi-trash"  title='Delete' rounded outlined className="deleteBtnFocus" onClick={() => deleteConfirm(rowData.id)} />
			</React.Fragment>
		);
	};

	const statusBodyTemplate = (data) => {
		return <Tag value={data.status} severity={getSeverity(data.status)}></Tag>;
	};



	return (
		<div className='dashboard'>
			<Sidebar isActive={isActive} />
			<div className='dashboardContainer'>
				<Navbar isActive={isActive} handleChange={handleChange} />
				<div>
					<div className='users-page'>
						<div className='container'>
							<div className='users-list'>
								<div className='addNewUser'>
									<div className='header-users'>
										<h6>Districts</h6>
									</div>
									<button className='addUserCls' title='Add User' onClick={() => setShowCreateForm(true)}>
										<i className='pi pi-plus' style={{ paddingRight: '6px' }}></i> Create
									</button>
								</div>
								<DataTable value={data} paginator rows={10}>
									<Column field='name' header="Name" sortable style={{ width: '35%', marginLeft: "5px" }} filter></Column>
                                    <Column field='code' header="Code" sortable style={{ width: '35%', marginLeft: "5px" }} filter></Column>
									<Column field='status' header="Status" body={statusBodyTemplate} sortable style={{ width: '15%' }} filter></Column>
									<Column style={{ width: '15%' }} header="Actions" body={actionBodyTemplate}></Column>
								</DataTable>
							</div>
						</div>
					</div>
					  <Dialog header="Create New District"
						visible={showCreateForm}
						style={{ width: '40vw' }}
						onHide={() => setShowCreateForm(false)}>
						<CreateDistrict visible={showCreateForm} userId={selectedUserId} selectedRowItem={selectedRowItem} setGetReloadData={setGetReloadData} setDistrictAdd={() => {
							setShowCreateForm(false);
							//getAllUsers();
						}} />
					</Dialog>
					 <Dialog header="Update District"
						visible={showEditMode}
						style={{ width: '39vw' }}
						onHide={() => setShowEditMode(false)}>
						<UpdateDistrict visible={showEditMode} userId={selectedUserId} updateData={selectedRowItem} updateDateId={selectedRowId} setGetReloadData={setGetReloadData} setUserEdited={() => {
							setShowEditMode(false);
							//getAllUsers();
						}} />
					</Dialog>   
					<ConfirmDialog />
					<ToastContainer />
				</div>
			</div>
		</div>
	)
}