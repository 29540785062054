import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};


 const options = {
	"slots": [
		{
			"id": "1",
			"time": "3:00 PM",
		},
		{
			"id": "2",
			"time": "4:00 PM",
		},
		{
			"id": "3",
			"time": "5:00 PM",
		},
		{
			"id": "4",
			"time": "6:00 PM",
		},
		{
			"id": "5",
			"time": "7:00 PM",
		},
		{
			"id": "6",
			"time": "8:00 PM",
		},{
			"id": "7",
			"time": "9:00 PM",
		},
		{
			"id": "8",
			"time": "10:00 PM",
		},
		{
			"id": "9",
			"time": "11:00 PM",
		},
		{
			"id": "10",
			"time": "12:00 AM",
		},
		{
			"id": "11",
			"time": "1:00 AM",
		},
		{
			"id": "12",
			"time": "2:00 AM",
		}
	]
};

export { useStyles, MenuProps, options };