export const data = [
	{
		id: 1,
		name: 'John smith',
		username: 'john123',
		email: 'johnsmith@gmail.com',
		phone: '97676766731',
		website: 'johnwebsite.in',
		total: 2795.95,
		status: 'On Hold',
		method: 'PayPal',
		date: '15 Minutes ago',
	},
	{
		id: 2,
		name: 'Ajay Kumar',
		username: 'ajay123',
		email: 'ajaykumar@gmail.com',
		phone: '97676766731',
		website: 'johnwebsite.in',
		total: 1195.95,
		status: 'Processing',
		method: 'PayPal',
		date: '23 Minutes ago',
	},
	{
		id: 3,
		name: 'Sarah',
		username: 'sarah123',
		email: 'sarah@gmail.com',
		phone: '97676766731',
		website: 'johnwebsite.in',
		total: 495.85,
		status: 'Completed',
		method: 'Visa',
		date: '1 Hour ago',
	},
	{
		id: 4,
		name: 'Joseph',
		email: 'joseph@gmail.com',
		phone: '97676766731',
		username: 'joseph123',
		website: 'johnwebsite.in',
		total: 150.45,
		status: 'Processing',
		method: 'MasterCard',
		date: '1 Hour ago',
	},
	{
		id: 5,
		name: 'Nitya',
		email: 'nitya@gmail.com',
		phone: '9790909011',
		username: 'nitya123',
		website: 'nityawebsite.in',
		total: 150.45,
		status: 'Processing',
		method: 'MasterCard',
		date: '1 Hour ago',
	},
	{
		id: 6,
		name: 'Anand',
		email: 'anand@gmail.com',
		phone: '9848912332',
		username: 'anand123',
		website: 'anandwebsite.in',
		total: 150.45,
		status: 'Processing',
		method: 'MasterCard',
		date: '1 Hour ago',
	},
	{
		id: 7,
		name: 'karthik',
		email: 'karthik@gmail.com',
		phone: '97676766731',
		username: 'karthik1232',
		website: 'karthikwebsite.in',
		total: 152.49,
		status: 'Processing',
		method: 'MasterCard',
		date: '1 Hour ago',
	},
	// {
	//   id: 8,
	//   name:'Joseph',
	//   email:'joseph@gmail.com',
	//   phone:'97676766731',
	//   username:'joseph123',
	//   website:'johnwebsite.in',
	//   total: 150.45,
	//   status: 'Processing',
	//   method: 'MasterCard',
	//   date: '1 Hour ago',
	// },
	// {
	//   id: 9,
	//   name:'Joseph',
	//   email:'joseph@gmail.com',
	//   phone:'97676766731',
	//   username:'joseph123',
	//   website:'johnwebsite.in',
	//   total: 150.45,
	//   status: 'Processing',
	//   method: 'MasterCard',
	//   date: '1 Hour ago',
	// },
	// {
	//   id: 10,
	//   name:'Joseph',
	//   email:'joseph@gmail.com',
	//   phone:'97676766731',
	//   username:'joseph123',
	//   website:'johnwebsite.in',
	//   total: 150.45,
	//   status: 'Processing',
	//   method: 'MasterCard',
	//   date: '1 Hour ago',
	// },
];

export const RecentData = [
	{
		id: 1,
		name: {
			first: 'John',
			last: 'Smith',
		},
		total: 2795.95,
		status: 'On Hold',
		method: 'PayPal',
		date: '15 Minutes ago',
	},
	{
		id: 2,
		name: {
			first: 'Chris',
			last: 'Adams',
		},
		total: 1195.95,
		status: 'Processing',
		method: 'PayPal',
		date: '23 Minutes ago',
	},
	{
		id: 3,
		name: {
			first: 'Sarah',
			last: 'Smith',
		},
		total: 495.85,
		status: 'Completed',
		method: 'Visa',
		date: '1 Hour ago',
	},
	{
		id: 4,
		name: {
			first: 'Joseph',
			last: 'Choo',
		},
		total: 150.45,
		status: 'Processing',
		method: 'MasterCard',
		date: '1 Hour ago',
	},
	// {
	//   id: 5,
	//   name: {
	// 	first: 'Steve',
	// 	last: 'Harding',
	//   },
	//   total: 175.25,
	//   status: 'On Hold',
	//   method: 'PayPal',
	//   date: '2 Hour ago',
	// },
	// {
	//   id: 6,
	//   name: {
	// 	first: 'Laura',
	// 	last: 'Croft',
	//   },
	//   total: 1295.75,
	//   status: 'Completed',
	//   method: 'Check',
	//   date: '3 Hour ago',
	// },
	// {
	//   id: 7,
	//   name: {
	// 	first: 'Michael',
	// 	last: 'Jones',
	//   },
	//   total: 89.95,
	//   status: 'Completed',
	//   method: 'MasterCard',
	//   date: '3 Hour ago',
	// },
	// {
	//   id: 8,
	//   name: {
	// 	first: 'James',
	// 	last: 'Bond',
	//   },
	//   total: 689.45,
	//   status: 'Completed',
	//   method: 'Visa',
	//   date: '7 Hour ago',
	// },
	// {
	//   id: 9,
	//   name: {
	// 	first: 'Haley',
	// 	last: 'Whiting',
	//   },
	//   total: 14.99,
	//   status: 'Completed',
	//   method: 'PayPal',
	//   date: '1 Day ago',
	// },
	// {
	//   id: 10,
	//   name: {
	// 	first: 'Tim',
	// 	last: 'Thomas',
	//   },
	//   total: 218.99,
	//   status: 'Completed',
	//   method: 'MasterCard',
	//   date: '1 Day ago',
	// },
];


export const userData = {
		"users": [
			{
				"user_id": "1",
				"email": "laxmanmalladi123@gmail.com",
				"password": "Laxman99"
			},
			{
				"user_id": "2",
				"email": "ajaykumar123@gmail.com",
				"password": "ajay1234"
			},
			{
				"user_id": "3",
				"email": "nareskumar123@gmail.com",
				"password": "naresh12"
			}
		]
	};

	export const barChartDropdown = [
			{
				"id": "1",
				"date": "Daily",
			},
			{
				"id": "2",
				"date": "Weekly",
			},
			{
				"id": "3",
				"date": "Monthly",
				"password": "naresh12"
			},
			{
				"id": "4",
				"date": "Yearly",
			},
		]


		export const dashboardUsers = [
			{
				id: 1,
				name: 'John smith',
				username: 'john123',
				email: 'johnsmith@gmail.com',
				phone: '97676766731',
				website: 'johnwebsite.in',
				total: 2795.95,
				status: 'On Hold',
				method: 'PayPal',
				date: '15 Minutes ago',
				slots:'slots1'
			},
			{
				id: 2,
				name: 'Ajay Kumar',
				username: 'ajay123',
				email: 'ajaykumar@gmail.com',
				phone: '97676766731',
				website: 'johnwebsite.in',
				total: 1195.95,
				status: 'Processing',
				method: 'PayPal',
				date: '23 Minutes ago',
				slots:'slots2'
			},
		]

		  export const slotsData = {
			"slots": [
				{
					"id": "1",
					"time": "09:00 AM",
				},
				{
					"id": "2",
					"time": "10:00 AM",
				},
				{
					"id": "3",
					"time": "11:00 AM",
				},
				{
					"id": "4",
					"time": "12:00 PM",
				},
				{
					"id": "5",
					"time": "1:00 PM",
				},
				{
					"id": "6",
					"time": "2:00 PM",
				},{
					"id": "7",
					"time": "3:00 PM",
				},
				{
					"id": "8",
					"time": "4:00 PM",
				},
				{
					"id": "9",
					"time": "5:00 PM",
				},
				{
					"id": "4",
					"time": "6:00 PM",
				},
				{
					"id": "10",
					"time": "1:00 PM",
				},
				{
					"id": "6",
					"time": "2:00 PM",
				}
			]
		};
		

		export const SpecializationData = [
			{
				id: 1,
				name: 'John smith',
				username: 'john123',
				email: 'johnsmith@gmail.com',
				phone: '97676766731',
				website: 'johnwebsite.in',
				total: 2795.95,
				status: 'On Hold',
				method: 'PayPal',
				date: '15 Minutes ago',
			},
			{
				id: 2,
				name: 'Ajay Kumar',
				username: 'ajay123',
				email: 'ajaykumar@gmail.com',
				phone: '97676766731',
				website: 'johnwebsite.in',
				total: 1195.95,
				status: 'Processing',
				method: 'PayPal',
				date: '23 Minutes ago',
			},
			{
				id: 3,
				name: 'Sarah',
				username: 'sarah123',
				email: 'sarah@gmail.com',
				phone: '97676766731',
				website: 'johnwebsite.in',
				total: 495.85,
				status: 'Completed',
				method: 'Visa',
				date: '1 Hour ago',
			},
		];

		export const ClinicsData = [
			{
				id: 1,
				clinic: 'Medical Clinic',	
			},
			{
				id: 2,
				clinic: 'Dental Clinic',
			},
			{
				id: 3,
				clinic: 'ENT Clinic',
			},
		];