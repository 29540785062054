import React, { useEffect, useState } from 'react'
import '../../AdminPanel/style.css';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SpecializationIcon from '@mui/icons-material/StarHalf';
import SymptomIcon from '@mui/icons-material/AcUnit';
 import MandalIcon from '@mui/icons-material/Equalizer';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import teleHelath_Img  from '../../TeleHealth/Images/telehealth_Logo.png';
import DoctorIcon from '@mui/icons-material/Medication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLandmark ,faBuildingFlag,faSchoolFlag ,faStethoscope, faUserMd} from '@fortawesome/free-solid-svg-icons';
import QualificationIcon from '@mui/icons-material/School';




const Sidebar = (props) => {
	const {isActive} = props;
  const user_Role = localStorage.getItem('userRole');
  const [uRole, setURole] = useState(user_Role);
 

  return (
    <div className= {isActive ? 'sidebar_darkMode': 'sidebar'}>
      <div className='top'>
        <Link to='/' style={{ textDecoration: 'none' }}>
          <div className='ml-3 mt-1'>
                <img width='190' height='70'  src={teleHelath_Img} />
            </div>
        </Link>
      </div>
      <hr />
      <div className='center'>
        <ul>
          <p className='title'>MAIN</p>
		  <Link to='/dashboard' style={{ textDecoration: 'none' }}>
          <li>
            <DashboardIcon className='iconCls' />
            <span>Dashboard</span>
          </li>
		  </Link>
      {  user_Role === "admin" &&
      <>
      <Link to='/clinicsList' style={{ textDecoration: 'none' }}>
          <li>
            
			
			<FontAwesomeIcon icon={faStethoscope} className='iconCls' />
            <span>Clinics</span>
          </li>
		  </Link>
		  <Link to='/specializationsList' style={{ textDecoration: 'none' }}>
          <li>
            <SpecializationIcon className='iconCls' />
            <span>Specializations</span>
          </li>
		  </Link>
		  <Link to='/symptomsList' style={{ textDecoration: 'none' }}>
          <li>
            <SymptomIcon className='iconCls' />
            <span>Symptoms</span>
          </li>
		  </Link>
		  <Link to='/qualificationsList' style={{ textDecoration: 'none' }}>
          <li>
            <QualificationIcon className='iconCls' />
            <span>Qualifications</span>
          </li>
		  </Link>
      <Link to='/mandalsList' style={{ textDecoration: 'none' }}>
          <li>
            <MandalIcon className='iconCls' />
            <span>Mandals</span>
          </li>
		  </Link>
		  <Link to='/districtsList' style={{ textDecoration: 'none' }}>
          <li>
            
			<FontAwesomeIcon icon={faSchoolFlag} className='iconCls' />

            <span>Districts</span>
          </li>
          {/* <FontAwesomeIcon icon="fa-solid fa-school-flag" /> */}
		  </Link>
		  <Link to='/SachivalayamsList' style={{ textDecoration: 'none' }}>
          <li>
            {/* <SymptomIcon className='iconCls' /> */}
			<FontAwesomeIcon icon={faLandmark} className='iconCls' />
            <span>Sachivalayams</span>
          </li>
		  </Link>
      </>
      }
      {/* {  user_Role === "clinic" &&
      <>	  
		  <Link to='/doctorsList' style={{ textDecoration: 'none' }}>
          <li>
            <DoctorIcon className='iconCls' />
			
            <span>Slots</span>
          </li>
		  </Link>
      </>
      } */}
      
      <Link to='/doctorsList' style={{ textDecoration: 'none' }}>
          <li>
            {/* <DoctorIcon className='iconCls' /> */}
            <FontAwesomeIcon icon={faUserMd} className='iconCls' />	

            <span>Doctors</span>
          </li>
		  </Link>
      <Link to='/pendingDoctorsList' style={{ textDecoration: 'none' }}>
          <li>
            {/* <DoctorIcon className='iconCls' /> */}

            <FontAwesomeIcon icon={faUserMd} className='iconCls' />	
			
            <span>Pending Doctors</span>
          </li>
		  </Link>

      
		  <Link to='/logout' style={{ textDecoration: 'none' }}>
          <li>
            <LogoutIcon className='iconCls' />
            <span>Logout</span>
          </li>
		  </Link>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;