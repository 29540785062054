import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Dialog } from 'primereact/dialog';
import { slotsData } from "../../AdminPanel/data/data";
import CreateSlotPage from "./CreateSlotPage";
import UpdateSlotPage from "./UpdateSlotPage.js";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import Sidebar from '../../AdminPanel/SideBar/Sidebar.tsx';
import Navbar from '../../AdminPanel/Navbar/Navbar.tsx';
import '../TeleHealth.css';
import { Link } from "react-router-dom";
import BulkSlotPage from "./BulkSlotPage.js";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";


function SlotPage(props) {
    const { setSelectedRowItem } = props;

    
    const [currentDate, setCurrentDate] = useState(new Date());
    const [slotdates, setSlotsDate] = useState([]);
    const [showAddMode, setShowAddMode] = useState(false);
    const [showEditMode, setShowEditMode] = useState(false);
    const [showBulkMode, setShowBulkMode] = useState(false);

    const [isActive, setIsActive] = useState(false);
    const [getReloadData, setGetReloadData] = useState(false);

    const params = useParams();
    const navigate = useNavigate();


    const apiEndPoint = 'https://ciaitelehealth.com/api';
    const [initialValues, setInitialValues] = useState({
		clinicId: "",		
		timeZone: "",
	});

    // const doctorName = window.atob(params.name); // decode the string
    const doctorName = params.name;
    const doctorId = params.docId;

    console.log('1111111', doctorId ,setSelectedRowItem)

    // const currentName = name.replace("%20", "");

    const fetchSlots = async (date) => {


            const url = `${apiEndPoint}/slots/get-slots`;

			//console.log('initialValues: ', initialValues);

			const formData = {
				//clinicId: initialValues.clinicId,
				doctorId: doctorId,				
                "timeZone": "Asia/Kolkata"                 
			};

			console.log('form', formData)
			const token = localStorage.getItem('accessToken');

			const options = {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			};
			await fetch(url, options)
				.then(response => response.json())
				.then(data => {
					if (data.msg === 'Success') {
                        setSlotsDate(data.data)
						// setInitialValues({
						// 	...initialValues,							
						// 	timeZone: ''
						// });
						//toast.success("Create slot Successfully.");
						// setTimeout(() => {
						// 	props.setUserAdded();
						// 	setGetReloadData(true);
						// 	navigate(`/slotpage/${doctorId}/${doctorName}/${doctorId}`);
						// }, 2000)

					} 
				})

				.catch(error => console.error('Error:', error));



    };

    useEffect(() => {
        //setSlotsDate(slotsData?.slots)
    }, []);

    useEffect(() => {
        fetchSlots(currentDate);
    }, [currentDate]);

    useEffect(() => {
        if (getReloadData) {

            setGetReloadData(false);
        }
    }, [getReloadData]);

    const handleAddSlot = () => {
        setShowAddMode(true);
    };

    const handleEditSlot = () => {
        setShowEditMode(true);
    };

    const handleBulkSlot = () => {
        setShowBulkMode(true);
    };

    function getCurrentDateTime() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    }

    const handleChange = () => {
        setIsActive(!isActive);
    }
    // console.log('122333333', currentName)
    return (
        <div className='dashboard'>
            <Sidebar isActive={isActive} />
            <div className='dashboardContainer'>
                <Navbar isActive={isActive} handleChange={handleChange} />
                <div className="page-container" style={{margin: '10px 10px', maxWidth: '100%'}}>
                    <header className="page-header">
                        <div style={{display:'flex',width:'100%'}}>
                            <div style={{width:'60%'}}>
                            <span>Slots for <b>Dr. {doctorName}</b></span>
                            </div>
                            <div style={{width:'40%',textAlign: 'right'}}>
                                <button className="add-slot-button" onClick={handleAddSlot}>Add Slot</button>&nbsp;
                                <button className="add-slot-button" onClick={handleEditSlot}>Edit Slot</button>&nbsp;
                                <button className="add-slot-button" onClick={handleBulkSlot}>Bulk Slot</button>
                            </div>
                        </div>
                        
                        
                    </header>
                   


                    <div className="date-picker-container">
                        <label>Select Current Date</label>
                         <LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: '450px' }}>
                            <DemoContainer
                                components={[
                                    'DesktopDateTimePicker'
                                ]}
                            >
                                <DemoItem label="" >
                                    <DateTimePicker defaultValue={dayjs(getCurrentDateTime())} />
                                </DemoItem>
                            </DemoContainer>
                        </LocalizationProvider> 
                    </div><br />

                    <div className="slot-container">
                        <h4>Slots Info:
                            {/* {currentDate.toDateString()} */}
                        </h4><br /> 
                        <ul className="slot-list">
                            {slotdates.map((slot, index) => (
                                <li key={slot.id} className="slot-item"> 
                                
                                {/* Doctor Name : {slot.doctorName} <br></br>
                                Clinic Name : {slot.clinicName} <br></br> */}
                                {slot.slotStartTime} <br></br>
                                {/* Slot End Time : {slot.slotEndTime}  */}
                                
                                </li>                                
                                                                
                            ))}
                            
                            {/* <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>9:00 AM</Link> </li>
                            <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>10:00 AM</Link></li>
                            <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>11:00 AM</Link></li>
                            <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>12:00 PM</Link></li>
                            <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>1:00 PM</Link></li>
                            <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>2:00 PM</Link></li>
                            <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>3:00 PM</Link></li>
                            <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>4:00 PM</Link></li>
                            <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>5:00 PM</Link></li>
                            <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>6:00 PM</Link></li>
                            <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>7:00 PM</Link></li>
                            <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>8:00 PM</Link></li>
                            <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>9:00 PM</Link></li>
                            <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>10:00 PM</Link></li>
                            <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>11:00 PM</Link></li>
                            <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>12:00 AM</Link></li>
                            <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>1:00 AM</Link></li>
                            <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>6:00 AM</Link></li>
                            <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>7:00 AM</Link></li>
                            <li className="slot-item"><Link style={{color: 'currentcolor'}} onClick={handleEditSlot}>8:00 AM</Link></li> */}
                        </ul>
                        <Dialog header="Add Slot Information"
                            visible={showAddMode}
                            style={{ width: '39vw' }}
                            onHide={() => setShowAddMode(false)}>

                            <CreateSlotPage setGetReloadData={setGetReloadData} doctorName={doctorName}
     doctorId={doctorId} setUserAdded={() => {
                                setShowAddMode(false);
                            }}
                            />
                        </Dialog>
                        <Dialog header="Edit Slot Information"
                            visible={showEditMode}
                            style={{ width: '39vw' }}
                            onHide={() => setShowEditMode(false)}>

                            <UpdateSlotPage setGetReloadData={setGetReloadData} setUserEdited={() => {
                                setShowEditMode(false);
                            }}
                            />
                        </Dialog>
                        <Dialog header="Bulk Slot Information"
                            visible={showBulkMode}
                            style={{ width: '39vw' }}
                            onHide={() => setShowBulkMode(false)}>

                            <BulkSlotPage setGetReloadData={setGetReloadData} setUserBulked={() => {
                                setShowBulkMode(false);
                            }}
                            />
                        </Dialog>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SlotPage;







