import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputSwitch } from 'primereact/inputswitch';

function UpdateDistrict(props) {
	const { updateData, setGetReloadData, selectedRowId } = props;
	const updateStatus = updateData && updateData?.status === 'Active' ? true :
		updateData && updateData?.status === 'In-Active' ? false : false;

	const [errorDistrictNameMessage, setErrorDistrictNameMessage] = useState("");
	const [errorCodeMessage, setErrorCodeMessage] = useState("");

	const [initialValues, setInitialValues] = useState({
		name: updateData ? updateData?.name : '',
		code: updateData ? updateData?.code : '',
		//stateId: updateData ? updateData?.stateId : ''
	});
	console.log('updateData', updateData)
	const [districtList, setDistrictList] = useState([])
	const [questStatus, setquestStatus] = useState(updateStatus);
	const navigate = useNavigate();
	const apiEndPoint = 'https://ciaitelehealth.com/api';

	useEffect(() => {
		getDistricts();
	}, [])


	const getDistricts = async () => {
		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/districts/get-districts-list`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				//localStorage.setItem("accessToken","");
				//navigate('/login');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			console.log('value12345',actualData)

			setDistrictList(actualData.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const validationForm = (type, value) => {

		let errorMessage = "";
		if (type === "name") {
			if (value === "") {
				errorMessage = "Enter District Name ";
			}
			else if (value.length < 3) {
				errorMessage = "Please enter atleast 3 Characters";
			}

			else if (value.length > 15) {
				errorMessage = "Please enter less than 15 Characters only";
			}
			setErrorDistrictNameMessage(errorMessage)
			setAllErrors({ ...allErrors, ['nameErr']: errorMessage });
		}

		if (type === "code") {
			if (value === '') {
				errorMessage = 'Select Code';
			}
			setErrorCodeMessage(errorMessage)
			setAllErrors({ ...allErrors, ['codeErr']: errorMessage })
		}
	}

	const [allErrors, setAllErrors] = useState({
		nameErr: "",
		codeErr: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInitialValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
		validationForm(name, value)
	};


	const UpdateDistrictData = async () => {

		if (initialValues.name === '' || initialValues.code === ""
		) {
			validationForm('name', initialValues.name);
			validationForm('code', initialValues.code);
		} else {
			const url = `${apiEndPoint}/districts/update-district`;
			const formData = {
				id: updateData?.id,
				name: initialValues.name,
				code: initialValues.code,
				stateId: updateData ? updateData?.stateId : '',
				status: questStatus === true ? 'Active' : 'In-Active'
			};

			const token = localStorage.getItem('accessToken');
			const options = {
				method: 'PUT',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			};

			await fetch(url, options)
				.then(response => response.json())
				.then(data => {
					if (data.msg === 'record updated') {
						setInitialValues({ ...initialValues, name: '', code: '', stateId: '' });
						setTimeout(() => {
							navigate('/districtsList');
						}, 2000)
						toast.success("District Updated Successfully.");
						props.setUserEdited();
						setGetReloadData(true);
					}
				})
				.catch(error => console.error('Error:', error));
		}
	}


	const handleChangeSwitch = (e) => {
		console.log(!questStatus)
		setquestStatus(!questStatus)
	};


	return (
		<div className='user-view _add-view'>
			<div className='box'>
				<div className='row'>
					<div>
						<p>
							<span>District Name</span>
							<input
								type='text'
								className='form-control'
								placeholder='Enter District Name'
								name='name'
								value={initialValues.name}
								onChange={handleChange}
								// onChange={e => setInitialValues({ ...initialValues, name: e.target.value })}
							/>
							<div className="errorSpName">{errorDistrictNameMessage}</div>
						</p>
					</div>



					<div>
						<p>
							<label>Code</label>
							<select
								className="doctorsListCls form-control"
								name='code'
								value={initialValues.code}
								// disabled={updateData?.code}
								onChange={handleChange}
								//  onChange={e => setInitialValues({ ...initialValues, code: e.target.value })}
							>
								<option value="">Select</option>
								{districtList.map((option, index) => (
									<option
										key={index}
										value={option.code}
									>
										{option.code}
									</option>
								))}
							</select>
							<div className="errorSpName">{errorCodeMessage}</div>
						</p>
					</div>
					{/* <div>
						<p>
							<label>State Name</label>
							<select
								className="doctorsListCls form-control"
								value={initialValues.stateId}
								onChange={e => setInitialValues({ ...initialValues, stateId: e.target.value })}
							>
								<option value="">Select</option>
								{districtList.map((option, index) => (
									<option
										key={index}
										value={option.id}
									>
										{option.name}
									</option>
								))}
							</select>
						</p>
					</div> */}

					<div style={{ display: 'flex' }}>
						<div style={{ paddingLeft: '2px' }}>Status</div>
						<div style={{ paddingLeft: '10px' }}><InputSwitch inputId="input-metakey" checked={questStatus} onChange={handleChangeSwitch} /></div>
					</div>




				</div>
			</div>
			<div className='specialBtnCls'>
				<button type="button" className="button-cancle-cls" style={{ marginRight: '15px' }} onClick={() => props.setUserEdited()}>Cancel</button>
				<button type="button" className="button-savecls" onClick={() => UpdateDistrictData()}>Update</button>
			</div>
			<ToastContainer />
		</div>
	)
}

export default UpdateDistrict;