
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../../AdminPanel/style.css';
import Sidebar from '../SideBar/Sidebar.tsx';
import Navbar from '../Navbar/Navbar.tsx';
import Widget from '../Widget/widget.tsx';
import BarChart from '../BarChart/BarChart.tsx';

function DashBoard() {
	const UAToken = localStorage.getItem("accessToken");
	const navigate = useNavigate();
	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		document.body.classList.add('bg-black-dashboard');
		return () => {
			document.body.classList.remove('bg-black-dashboard');
		};
	}, []);

	useEffect(() => {
		if (UAToken && UAToken !== "" && UAToken !== null) {
			navigate('/dashboard');
		} else {
			navigate('/');
		}
	}, [UAToken]);

	useEffect(() => {
		if (isActive) {
			document.body.classList.add('bg-black-darkMode-dashboard');
			document.body.classList.remove('bg-black-dashboard');
		} else {
			document.body.classList.remove('bg-black-darkMode-dashboard');
			document.body.classList.add('bg-black-dashboard');
		}
	}, [isActive]);

	const handleChange = () => {
		setIsActive(!isActive);
	}

	return (
		<div className='dashboard'>
			<Sidebar isActive={isActive} />
			<div className='dashboardContainer'>
				<Navbar isActive={isActive} handleChange={handleChange} />
				<div className='widgets'>
					<Widget type='user' />
				</div>
				<BarChart isActive={isActive} />				
			</div>
		</div>
	);
};

export default DashBoard;
