import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CreateMandal(props) {
	const { setGetReloadData, districtId } = props;

	const [errorMandalNameMessage, setErrorMandalNameMessage] = useState("");
	const [errorDistrictIdMessage, setErrorDistrictIdMessage] = useState("");


	const [initialValues, setInitialValues] = useState({
		name: '',
		districtId: ''
	});

	const [data, setData] = useState([]);

	const navigate = useNavigate();
	const apiEndPoint = 'https://ciaitelehealth.com/api';

	useEffect(() => {
		getMandalsList();
	}, [])
	
	console.log('districtId', districtId)

	const getMandalsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');

			const response = await fetch(`${apiEndPoint}/mandals/get-mandals-list`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				// localStorage.setItem("accessToken","");
				// navigate('/login');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setData(actualData.data);
			console.log('22222222222222222----->', actualData);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};
	

	const validationForm = (type, value) => {

		let errorMessage = "";
		if (type === "name") {
			if (value === "") {
				errorMessage = "Enter Mandal Name ";
			}
			else if (value.length < 3) {
				errorMessage = "Please enter atleast 3 Characters";
			}

			else if (value.length > 10) {
				errorMessage = "Please enter less than 10 Characters only";
			}
			setErrorMandalNameMessage(errorMessage)
			setAllErrors({ ...allErrors, ['nameErr']: errorMessage });
		}

		if (type === "districtId") {
			if (value === '') {
				errorMessage = 'Select District Name';
			}
			setErrorDistrictIdMessage(errorMessage)
			setAllErrors({ ...allErrors, ['districtIdErr']: errorMessage })
		}
		
	}

	const [allErrors, setAllErrors] = useState({
		nameErr: "",
		districtIdErr:""

	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInitialValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
		validationForm(name, value)
	};


	const CreateMandalData = async () => {

		if (initialValues.name === '' ||  initialValues.districtId === ""  
			
		) {
			validationForm('name', initialValues.name);
		    validationForm('districtId', initialValues.districtId);

		}else{
			
		const url = `${apiEndPoint}/mandals/create-mandal`;
		const formData = {
			name: initialValues.name,
			districtId: initialValues.districtId,

		};
		const token = localStorage.getItem('accessToken');

		const options = {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(formData),
		};
		await fetch(url, options)
			.then(response => response.json())
			.then(data => {
				if (data.msg === "Mandal created successfully") {
					setInitialValues({
						...initialValues,
						name: '',
						districtId: '',

					});
					setTimeout(() => {
						navigate('/mandalsList');
					}, 2000)
					toast.success("Mandal created successfully");
					props.setMandalAdd();
					setGetReloadData(true);
				}

			})
			.catch(error => console.error('Error:', error));
		}
	}

	return (
		<div className='user-view _add-view'>
			<div className='box'>
				<div className='row'>
					<div>
						<p>
							<label style={{marginLeft:"12px"}}>Mandal Name</label>
							<input
								type='text'
								className='form-control'
								name='name'
								placeholder='Enter Mandal Name'
								value={initialValues.name}
								onChange={handleChange}
							/>
							<div className="errorSpName">{errorMandalNameMessage}</div>
						</p>
					</div>
					
					 <div>
						<p>
							<label style={{marginLeft:"12px"}}>District Name</label>
							<select
								className="doctorsListCls form-control"
								name='districtId'
								value={initialValues.districtId}
								onChange={handleChange}
							>
								<option value="">Select</option>
								{data.map((option, index) => (
									<option
										key={index}
										value={option.districtId}
										      
									>
										{option.districtName}
									</option>
								))}
							</select>
							<div className="errorSpName">{errorDistrictIdMessage}</div>
						</p>
					</div> 
				</div>
			</div>
			<div className='specialBtnCls'>
				<button type="button" className="button-cancle-cls" style={{ marginRight: '15px' }} onClick={() => props.setMandalAdd()}>Cancel</button>
				<button type="button" className="button-savecls" onClick={() => CreateMandalData()}>Save</button>
			</div>
			<ToastContainer />
		</div>
	)
}

export default CreateMandal;