import React, { useEffect, useState } from 'react'
import axios from 'axios';

const initialUserInfo = {
    id: '',
    name: '',
    username: '',
    email: '',
    phone: '',
    website: '',
}

function EditUser(props) {
    const [userInfo, setUserInfo] = useState(initialUserInfo);

    useEffect(() => {
        setUserInfo({ ...userInfo,id: props.userId})
        fetchUserData();
    }, []);

	console.log('props',props)
    const fetchUserData = async () => {
        try {
            const response = await axios.get('http://localhost:4000/users/' + props.userId);
            if (response) {
                console.log(response)
                setUserInfo(response.data);
            }
            return
        }
        catch (e) {
            console.log(e)
        }
    }

    const editExistUser = async () => {
        try {
            const response = await axios.put('http://localhost:4000/users/' + props.userId, userInfo);
            if (response) {
                props.setUserEdited();
            }
        }
        catch (e) {
            console.log(e)
        }
    }


    return (
        <div className='user-view _add-view'>
            <div className='box'>
                <div className='row'>
                    <div className='col-sm-12 col-md-6'>
                        <p>
                            <span>Full Name</span>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Enter Name'
                                value={userInfo.name}
								autoComplete='off'
                                onChange={e => setUserInfo({ ...userInfo, name: e.target.value })}
                            />
                        </p>
                    </div>
                    <div className='col-sm-12 col-md-6'>
                        <p>
                            <span>Username</span>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Enter Username'
								autoComplete='off'
                                value={userInfo.username}
                                onChange={e => setUserInfo({ ...userInfo, username: e.target.value })}
                            />
                        </p>
                    </div>
                    <div className='col-sm-12 col-md-6'>
                        <p>
                            <span>Email Address</span>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Enter Email Address'
								autoComplete='off'
                                value={userInfo.email}
                                onChange={e => setUserInfo({ ...userInfo, email: e.target.value })}
                            />
                        </p>
                    </div>
                    <div className='col-sm-12 col-md-6'>
                        <p>
                            <span>Phone Number</span>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Enter Phone Number'
								autoComplete='off'
                                value={userInfo.phone}
                                onChange={e => setUserInfo({ ...userInfo, phone: e.target.value })}
                            />
                        </p>
                    </div>
                    <div className='col-sm-12 col-md-6'>
                        <p>
                            <span>Website</span>
                            <input
                                type='text'
                                className='form-control'
                                placeholder='Enter Website'
								autoComplete='off'
                                value={userInfo.website}
                                onChange={e => setUserInfo({ ...userInfo, website: e.target.value })}
                            />
                        </p>
                    </div>

                </div>
            </div>
			<div class='submitBtnCls'>
			    <button type="button" class="btn btn-danger" style={{marginRight:'15px'}} onClick={() => props.setUserEdited()}>Cancel</button>
				<button type="button" class="btn btn-primary" onClick={() => editExistUser()}>Update</button>
			</div>
        </div>
    )
}

export default EditUser