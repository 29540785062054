import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function CreateQualification(props) {

	const { setGetReloadData, setUserAdded } = props;

	const [errorqualificationNameMessage, setErrorQualificationNameMessage] = useState("");

	const [initialValues, setInitialValues] = useState({
		qualification: ''
	});
	const navigate = useNavigate();
	const apiEndPoint = 'https://ciaitelehealth.com/api';


	const addQualificationInfo = async () => {

		if (initialValues.qualification === '') {
			validationForm('qualification', initialValues.qualification);
		} else {

			const url = `${apiEndPoint}/qualifications/create-qualification`;
			const formData = {
				qualification: initialValues.qualification,
			};
			const token = localStorage.getItem('accessToken');
			const options = {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			};
			await fetch(url, options)
				.then(response => response.json())
				.then(data => {
					if (data.msg === "reacord created") {
						setInitialValues({ ...initialValues, qualification: '' });
						setTimeout(() => {
							navigate('/qualificationsList');
						}, 1000)
						setUserAdded();
						setGetReloadData(true)
						toast.success("Qualification Saved Successfully.");
					}
				})
				.catch(error => console.error('Error:', error));
		}
	}

	const validationForm = (type, value) => {

		let errorMessage = "";
		if (type === "qualification") {
			if (value === "") {
				errorMessage = "Enter Qualification Name ";
			}
			else if (value.length < 3) {
				errorMessage = "Please enter atleast 3 Characters";
			}

			else if (value.length > 10) {
				errorMessage = "Please enter less than 10 Characters only";
			}
			setErrorQualificationNameMessage(errorMessage)
			setAllErrors({ ...allErrors, ['qualificationNameErr']: errorMessage });
		}
	}

	const [allErrors, setAllErrors] = useState({
		qualificationNameErr: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInitialValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
		validationForm(name, value)
	};

	return (
		<div className='user-view _add-view'>
			<div className='box'>
				<div className='row'>
					<div>
						<p>
							<span>Qualification Name</span>
							<input
								type='text'
								className='form-control'
								placeholder='Enter Qualification Name'
								name='qualification'
								value={initialValues.qualification}
								onChange={handleChange}
							// onChange={e => setInitialValues({ ...initialValues, qualification: e.target.value })}
							/>
							<div className='errorSpName'>{errorqualificationNameMessage}</div>
						</p>
					</div>
				</div>
			</div>
			<div className='specialBtnCls'>
				<button type="button" className="button-cancle-cls" style={{ marginRight: '15px' }} onClick={() => setUserAdded()}>Cancel</button>
				<button type="button" className="button-savecls" onClick={() => addQualificationInfo()}>Save</button>
			</div>
			<ToastContainer />
		</div>
	)
}

export default CreateQualification;