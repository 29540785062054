import React, { useState, useEffect } from 'react';
import '../../AdminPanel/style.css';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { dashboardUsers } from "../../AdminPanel/data/data.js";
import Sidebar from '../../AdminPanel/SideBar/Sidebar.tsx';
import Navbar from '../../AdminPanel/Navbar/Navbar.tsx';
import CreateDoctor from './CreateDoctor.js';
import UpdateDoctor from './UpdateDoctor.js';
import ReviewDoctorView from './ReviewDoctorView';


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Link } from '@mui/material';
import { Tag } from 'primereact/tag';
import stringifyReactNode from 'rsuite/esm/internals/utils/stringifyReactNode';


export default function DoctorsList() {
	const UAToken = localStorage.getItem("accessToken");
	const navigate = useNavigate();

	const [doctorsList, setDoctorsList] = useState([]);
	const [showEditMode, setShowEditMode] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState(null);
	const [isActive, setIsActive] = useState(false);
	const [data, setData] = useState([]);
	const [showAddMode, setShowAddMode] = useState(false);
	const [getReloadData, setGetReloadData] = useState(false);

	const getSeverity = (status) => {
		switch (status) {
			case 'Active':
				return 'active-status';
			case 'In-Active':
				return 'In-active-status';
		}
	};

	const apiEndPoint = 'https://ciaitelehealth.com/api';

	useEffect(() => {
		setDoctorsList(dashboardUsers)
	}, []);

	useEffect(() => {
		const token = localStorage.getItem('accessToken');
		if (!!token) {
			getDoctorsList();
		}
	}, []);

	useEffect(() => {
		if (UAToken && UAToken !== "" && UAToken !== null) {
			//navigate('/doctorsList');
		}
		else {
			//navigate('/')
		}
	}, [UAToken]);

	useEffect(() => {
		if (getReloadData) {
			getDoctorsList();
			setGetReloadData(false);
		}
	}, [getReloadData])

	const getDoctorsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');

			const response = await fetch(`${apiEndPoint}/doctors/get-doctors-list?search=&page=${1}&pageSize=${10}`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				//localStorage.setItem("accessToken","");
				//navigate('/login');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setData(actualData.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const deleteConfirm = (slotId) => {
		confirmDialog({
			message: 'Are you sure you want to delete this Doctor?',
			header: 'Confirmation',
			accept: () => deleteDoctor(slotId),
		});
	}
	
	const handleChange = () => {
		setIsActive(!isActive);
	} 

	const reviewConfirm = (data) => {

		localStorage.setItem('doctorDetails', JSON.stringify(data));

		//setSelectedUserId(data)

		navigate(`/reviewDoctor/${data.id}`);		
	}

	const slotsConfirm = (slotId) => {
		navigate(`/slotpage/${slotId}`);		
	}

	const statusBodyTemplate = (data) => {
		return <Tag value={data.status} severity={getSeverity(data.status)}></Tag>;
	};

	const deleteDoctor = async (Id) => {
		try {
			const token = localStorage.getItem('accessToken');
			await fetch(`${apiEndPoint}/doctors/delete-doctor/${Id}`, {
				method: 'DELETE',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			})
				.then(response => response.json())
				.then(actualData => {
					console.log('actualData ', actualData);
					toast.success("Doctor Record Deleted Successfully.");
					getDoctorsList();
				})
				.catch(err => {
					console.log(err.message);
				});
		}
		catch (e) {
			console.log(e)
		}
	}


	const actionBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				{/* <Link style={{ color: '#0b7c69' }} title='Slots' onClick={() => slotsConfirm(rowData.id)} >Slots</Link>&nbsp; */}

				<Link style={{ color: '#0b7c69' }} title='Slots' onClick={() => reviewConfirm(rowData)} >Review</Link>&nbsp;	
				
				<Button style={{ background: '#0b7c69', color: '#fff' }} icon="pi pi-pencil" title='Edit' rounded outlined className="mr-2" onClick={() => {
					setSelectedUserId(rowData)
					setShowEditMode(true)

				}} />

				<Button icon="pi pi-trash" title='Delete' rounded outlined className="deleteBtnFocus" onClick={() => deleteConfirm(rowData.id)} />
				{/* <Link href={`/slotpage/${rowData.id}`} target="_blank" style={{ cursor: 'pointer' }} underline="always">
					<div>Slots</div>
				</Link> */}

			</React.Fragment>
		);
	};
	console.log('selectedUserId', selectedUserId)

	return (
		<div className='dashboard'>
			<Sidebar isActive={isActive} />
			<div className='dashboardContainer'>
				<Navbar isActive={isActive} handleChange={handleChange} />
				<div className='users-page'>
					<div className='container'>
						<div className='users-list'>
							<div className='addNewUser'>
								<div className='header-users'>
									<h6>Review Doctor Details</h6>
								</div>
								{/* <button className='addUserCls' title='Add User'
									onClick={() => setShowAddMode(true)}
								>
									<i className='pi pi-plus' style={{ paddingRight: '6px' }}></i> Create
								</button> */}

								
							</div>
							
							<div>
							<ReviewDoctorView />

							</div>
						</div>
					</div>
					<Dialog header="Create New Doctor"
						visible={showAddMode}
						style={{ width: '40vw' }}
						onHide={() => showAddMode(false)}>
						<CreateDoctor visible={showAddMode} userId={selectedUserId} setGetReloadData={setGetReloadData} setUserAdded={() => {
							showAddMode(false)
						}}
						/>
					</Dialog>
					<Dialog header="Update Doctor"
						visible={showEditMode}
						style={{ width: '39vw' }}
						onHide={() => setShowEditMode(false)}>

						<UpdateDoctor visible={showEditMode} updateDoctordata={selectedUserId} setUserEdited={() => {
							setShowEditMode(false);
							//getAllUsers();
						}} />
					</Dialog>
					<ConfirmDialog />
					<ToastContainer />
				</div>
			</div>
		</div>
	)
}