import React, { useEffect, useState } from 'react'
import axios from 'axios';

const initialUserInfo = {
	name: '',
	username: '',
	email: '',
	phone: '',
	website: '',
}

function CreateUser(props) {
	const [userInfo, setUserInfo] = useState(initialUserInfo);

	useEffect(() => {
	}, []);

	const addNewUser = async () => {
		try {
			const response = await axios.post('http://localhost:4000/users', userInfo);
			if (response) {
				props.setUserAdded();
			}
		}
		catch (e) {
			console.log(e)
		}
	}
	const handleClearForm = () => {
		setUserInfo({ ...userInfo, name: '', username: '', email: '', phone: '', website: '' })
	}


	return (
		<div className='user-view _add-view'>
			<div className='box'>
				<div className='row'>
					<div className='col-sm-12 col-md-6'>
						<p>
							<span>Full Name</span>
							<input
								type='text'
								className='form-control'
								placeholder='Enter Full Name'
								value={userInfo.name}
								onChange={e => setUserInfo({ ...userInfo, name: e.target.value })}
							/>
						</p>
					</div>
					<div className='col-sm-12 col-md-6'>
						<p>
							<span>Username</span>
							<input
								type='text'
								className='form-control'
								placeholder='Enter Username'
								value={userInfo.username}
								onChange={e => setUserInfo({ ...userInfo, username: e.target.value })}
							/>
						</p>
					</div>
					<div className='col-sm-12 col-md-6'>
						<p>
							<span>Email Address</span>
							<input
								type='text'
								className='form-control'
								placeholder='Enter Email Address'
								value={userInfo.email}
								onChange={e => setUserInfo({ ...userInfo, email: e.target.value })}
							/>
						</p>
					</div>
					<div className='col-sm-12 col-md-6'>
						<p>
							<span>Phone Number</span>
							<input
								type='text'
								className='form-control'
								placeholder='Enter Phone Number'
								value={userInfo.phone}
								onChange={e => setUserInfo({ ...userInfo, phone: e.target.value })}
							/>
						</p>
					</div>
					<div className='col-sm-12 col-md-6'>
						<p>
							<span>Website</span>
							<input
								type='text'
								className='form-control'
								placeholder='Enter Website'
								value={userInfo.website}
								onChange={e => setUserInfo({ ...userInfo, website: e.target.value })}
							/>
						</p>
					</div>

				</div>
			</div>
			<div className='submitBtnCls'>
				<button type="button" className="btn btn-danger" style={{marginRight:'15px'}} onClick={() => props.setUserAdded()}>Cancel</button>
				<button type="button" className="btn btn-primary" onClick={() => addNewUser()}>Save</button>
			</div>
		</div>
	)
}

export default CreateUser