import React, { useState, useEffect } from 'react';
import '../../AdminPanel/style.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";

function UpdateDoctor(props) {

	const { updateDoctordata, setGetReloadData } = props;
	const [specializationList, setSpecializationList] = useState([])
	const [QualificationsList, setQualificationList] = useState([])
	const [clinicsList, setClinicsList] = useState([]);

	const [initialValues, setInitialValues] = useState({
		name: updateDoctordata ? updateDoctordata?.name : '',
		bio: updateDoctordata ? updateDoctordata?.bio : '',
		phoneNumber: updateDoctordata ? updateDoctordata?.phoneNumber : '',
		email: updateDoctordata ? updateDoctordata?.email : '',
		isOfflineConsultationAvailable: false,
		isOnlineConsultationAvailable: false,
		gender: '',
		age: '',
		specializations:[{ id: '', experience: '' }],
		qualifications: [{ id: '', college: '', location: '', fromYear: '', toYear: '' }],
		clinics: [{ id: '' }],
	});

	const navigate = useNavigate();

	const apiEndPoint = 'https://ciaitelehealth.com/api';

	useEffect(() => {
		const token = localStorage.getItem('accessToken');
		if (!!token) {
			getSpecializations();
			getQualificationsList();
			getClinicsList();
		}
	}, []);

	const getSpecializations = async () => {
		try {
			const token = localStorage.getItem('accessToken');

			const response = await fetch(`${apiEndPoint}/specializations/get-specializations-list`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				// localStorage.setItem("accessToken","");
				// navigate('/login');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setSpecializationList(actualData.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const getQualificationsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');

			const response = await fetch(`${apiEndPoint}/qualifications/get-qualifications-list`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				// localStorage.setItem("accessToken","");
				// navigate('/login');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setQualificationList(actualData.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}

	const getClinicsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');

			const response = await fetch(`${apiEndPoint}/clinics/get-clinics-list?search=&page=${1}&pageSize=${10}`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				// localStorage.setItem("accessToken","");
				// navigate('/login');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setClinicsList(actualData.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const UpdateDoctorData = async (e) => {
		e.preventDefault();
		const url = `${apiEndPoint}/doctors/update-doctor`;
		const formData = {
			name: initialValues.name,
			bio: initialValues.bio,
			phoneNumber: initialValues.phoneNumber,
			email: initialValues.email,
			isOfflineConsultationAvailable: initialValues.isOfflineConsultationAvailable,
			isOnlineConsultationAvailable: initialValues.isOnlineConsultationAvailable,
			gender: initialValues.gender,
			age: initialValues.age,
			specializations: initialValues.specializations,
			qualifications: initialValues.qualifications,
			clinics: initialValues.clinics,

		};
		const token = localStorage.getItem('accessToken');
		const options = {
			method: 'PUT',
			headers: {
				'Authorization': `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(formData),
		};

		await fetch(url, options)
			.then(response => response.json())
			.then(data => {
				if (data.msg === 'success') {
					console.log('data', data)
					setInitialValues({
						...initialValues,
						name: '',
						bio: '',
						phoneNumber: '',
						email: '',
						isOfflineConsultationAvailable: false,
						isOnlineConsultationAvailable: false,
						consultationFee: '',
						gender: '',
						age: '',
						specializations: [{ id: '', experience: '' }],
						qualifications: [{ id: '', college: '', location: '', fromYear: '', toYear: '' }],
						clinics: []
					});
					setTimeout(() => {
						navigate('/doctorsList');
					}, 2000)
					toast.success("Doctor Updated Successfully.");
					props.setUserEdited();
					setGetReloadData(true);
				}
			})
			.catch(error => console.error('Error:', error));
	}

	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target;
		setInitialValues(prevState => ({
			...prevState,
			[name]: type === 'checkbox' ? checked : value
		}));
	};

	const handleArrayInputChange = (e, arrayName, index) => {
		const { name, value } = e.target;
		setInitialValues(prevState => ({
			...prevState,
			[arrayName]: [
				...prevState[arrayName].slice(0, index),
				{
					...prevState[arrayName][index],
					[name.split('.')[1]]: value
				},
				...prevState[arrayName].slice(index + 1)
			]
		}));
	};
	return (
		<>
			<div>
				<form className="form-container-doctor" >
					<div className="form-group">
						<label htmlFor="name">Name:</label>
						<input
							type="text"
							id="name"
							name="name"
							value={initialValues.name}
							onChange={handleInputChange}
							placeholder="Enter Name"
						/>
					</div>

					<div className="form-group">
						<label htmlFor="bio">Bio:</label>
						<textarea
							id="bio"
							name="bio"
							value={initialValues.bio}
							onChange={handleInputChange}
							placeholder="Enter Bio"
						/>
					</div>

					<div className="form-group">
						<label htmlFor="phoneNumber">Phone Number:</label>
						<input
							type="tel"
							id="phoneNumber"
							name="phoneNumber"
							value={initialValues.phoneNumber}
							onChange={handleInputChange}
							placeholder="Enter Phone Number"
						/>
					</div>

					<div className="form-group">
						<label htmlFor="email">Email:</label>
						<input
							type="email"
							id="email"
							name="email"
							value={initialValues.email}
							onChange={handleInputChange}
							placeholder="Enter Email"
						/>
					</div>

					<div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>
						<label htmlFor="isOfflineConsultationAvailable">Offline Consultation Available</label>
						<input
							type="checkbox"
							id="isOfflineConsultationAvailable"
							name="isOfflineConsultationAvailable"
							className='checkboxAlignCls'
							checked={initialValues.isOfflineConsultationAvailable}
							onChange={handleInputChange}
						/>
					</div>

					<div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>
						<label htmlFor="isOnlineConsultationAvailable">Online Consultation Available</label>
						<input
							type="checkbox"
							id="isOnlineConsultationAvailable"
							name="isOnlineConsultationAvailable"
							className='checkboxAlignCls'
							checked={initialValues.isOnlineConsultationAvailable}
							onChange={handleInputChange}
							style={{ marginRight: '5px' }} // Adjust margin as needed
						/>
					</div>

					<div className="form-group">
						<label htmlFor="consultationFee">Consultation Fee:</label>
						<input
							type="text"
							id="consultationFee"
							name="consultationFee"
							value={initialValues.consultationFee}
							onChange={handleInputChange}
							placeholder="Enter Consultation Fee"
						/>
					</div>

					<div className="form-group radioContainer" style={{ display: 'flex', alignItems: 'center' }}>
						<label htmlFor="gender">Gender:</label>
						<input
							type="radio"
							name="gender"
							value="male"
							checked={initialValues.gender === "male"}
							onChange={handleInputChange}
						/>
						Male &nbsp;
						<input
							type="radio"
							name="gender"
							value="female"
							checked={initialValues.gender === "female"}
							onChange={handleInputChange}
						/>
						Female &nbsp;
						<input
							type="radio"
							name="gender"
							value="other"
							checked={initialValues.gender === "other"}
							onChange={handleInputChange}
						/>
						Other
					</div>

					<div className="form-group">
						<label htmlFor="age">Age:</label>
						<input
							type="number"
							id="age"
							name="age"
							value={initialValues.age}
							onChange={handleInputChange}
							placeholder="Enter Age"
						/>
					</div>

					<div className="form-group">
						<label>Specialization:</label>
						{initialValues.specializations.map((specialization, index) => (
							<div key={index} className="array-input">
								<select
									className="doctorsListCls form-control"
									style={{ display: 'flex' }}
									name={`specializations[${index}].id`}
									value={specialization.id}
									onChange={(e) => handleArrayInputChange(e, 'specializations', index)}
								>
									<option value="">Select</option>
									{specializationList.map((option, index) => (
										<option
											key={index}
											value={option.id}
										>
											{option.specialization}
										</option>
									))}
								</select>
								<input
									type="text"
									name={`specializations[${index}].experience`}
									style={{
										marginLeft: '10px',
										paddingRight: '10px'
									}}
									value={specialization.experience}
									onChange={(e) => handleArrayInputChange(e, 'specializations', index)}
									placeholder="Experience" />
							</div>
						))}
					</div>

					<div className="form-group">
						<label>Qualification:</label>
						{initialValues.qualifications.map((qualification, index) => (
							<div key={index} className="array-input">
								<select
									className="doctorsListCls form-control"
									style={{ display: 'flex', marginRight: '10px' }}
									name={`qualifications[${index}].id`}
									value={qualification.id}
									onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
								>
									<option value="">Select</option>
									{QualificationsList.map((option, index) => (
										<option
											key={index}
											value={option.id}
										>
											{option.qualification}
										</option>
									))}
								</select>
								<input
									type="text"
									name={`qualifications[${index}].college`}
									value={qualification.college}
									onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
									placeholder="College"
								/>
								<input
									type="text"
									name={`qualifications[${index}].location`}
									value={qualification.location}
									onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
									placeholder="Location"
								/>
								<input
									type="text"
									name={`qualifications[${index}].fromYear`}
									value={qualification.fromYear}
									onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
									placeholder="From Year"
								/>
								<input
									type="text"
									name={`qualifications[${index}].toYear`}
									value={qualification.toYear}
									onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
									placeholder="To Year"
								/>
							</div>
						))}
					</div>

					<div className="form-group">
						<label>Clinic:</label>
						{initialValues.clinics.map((clinic, index) => (
							<div key={index} className="array-input">

								<select
									className="doctorsListCls form-control"
									style={{ display: 'flex', marginRight: '10px' }}
									name={`clinics[${index}].id`}
									value={clinic.id}
									onChange={(e) => handleArrayInputChange(e, 'clinics', index)}
								>
									<option value="">select</option>
									{clinicsList.map((option, index) => (
										<option
											key={index}
											value={option.id}
										>
											{option.name}
										</option>
									))}

								</select>
							</div>
						))}
					</div>

					<div className="button-container">
						<button className='button-savecls' type="submit" onClick={UpdateDoctorData}>Update</button>&nbsp;&nbsp;
						<button className='button-cancle-cls' type="reset" onClick={() => props.setUserEdited()}>Cancel</button>
					</div>
					<ToastContainer />
				</form>
			</div>
		</>
	);
}

export default UpdateDoctor;