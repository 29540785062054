import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputSwitch } from 'primereact/inputswitch';


function UpdateQualification(props) {
	const { updateData, setGetReloadData, setUserEdited } = props;

	const updateStatus = updateData && updateData?.status === 'Active' ? true :
		updateData && updateData?.status === 'In-Active' ? false : false;

	const [errorqualificationNameMessage, setErrorQualificationNameMessage] = useState("");


	const [initialValues, setInitialValues] = useState({
		qualification: updateData ? updateData?.qualification : '',
		id: updateData ? updateData?.id : '',
		status: updateData ? updateData?.status : ''
	});

	const [questStatus, setquestStatus] = useState(updateStatus);

	const navigate = useNavigate();
	const apiEndPoint = 'https://ciaitelehealth.com/api';

	const UpdateQualificationInfo = async () => {

		if (initialValues.qualification === '') {
			validationForm('qualification', initialValues.qualification);
		} else {

			const url = `${apiEndPoint}/qualifications/update-qualification`;
			const formData = {
				qualification: initialValues.qualification,
				id: updateData?.id,
				status: questStatus === true ? 'Active' : 'In-Active'
				// status: updateData?.status
			};
			const token = localStorage.getItem('accessToken');
			const options = {
				method: 'PUT',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			};

			await fetch(url, options)
				.then(response => response.json())
				.then(data => {
					if (data.msg === 'record updated') {
						setInitialValues({
							...initialValues,
							qualification: '',
							id: '',
							status: '',
						});
						toast.success("Qualification Updated Successfully.");
						setTimeout(() => {
							navigate('/qualificationsList');
						}, 2000)
						setUserEdited();
						setGetReloadData(true)
					}
				})
				.catch(error => console.error('Error:', error));

		}
	}

	const validationForm = (type, value) => {

		let errorMessage = "";
		if (type === "qualification") {
			if (value === "") {
				errorMessage = "Enter Qualification Name ";
			}
			else if (value.length < 3) {
				errorMessage = "Please enter atleast 3 Characters";
			}

			else if (value.length > 10) {
				errorMessage = "Please enter less than 10 Characters only";
			}
			setErrorQualificationNameMessage(errorMessage)
			setAllErrors({ ...allErrors, ['qualificationNameErr']: errorMessage });
		}
	}

	const [allErrors, setAllErrors] = useState({
		qualificationNameErr: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInitialValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
		validationForm(name, value)
	};

	const handleChangeSwitch = (e) => {
		console.log(!questStatus)
		setquestStatus(!questStatus)
	};

	return (
		<div className='user-view _add-view'>
			<div className='box'>
				<div className='row'>
					<div>
						<p>
							<span>Qualification Name</span>
							<input
								type='text'
								className='form-control'
								placeholder='Enter Qualification Name'
								name='qualification'
								value={initialValues.qualification}
								onChange={handleChange}
							// onChange={e => setInitialValues({ ...initialValues, qualification: e.target.value })}
							/>
							<div className='errorSpName'>{errorqualificationNameMessage}</div>
						</p>
					</div>
					<div style={{ display: 'flex' }}>
						<div style={{ paddingLeft: '2px' }}>Status</div>
						<div style={{ paddingLeft: '10px' }}><InputSwitch inputId="input-metakey" checked={questStatus} onChange={handleChangeSwitch} /></div>
					</div>
				</div>
			</div>
			<div className='specialBtnCls'>
				<button type="button" className="button-cancle-cls" style={{ marginRight: '15px' }} onClick={() => setUserEdited()}>Cancel</button>
				<button type="button" className="button-savecls" onClick={() => UpdateQualificationInfo()}>Update</button>
			</div>
			<ToastContainer />
		</div>
	)
}

export default UpdateQualification;