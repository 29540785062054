import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import CreateUser from './CreateUser';
import EditUser from './EditUser';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { data } from '../data/data';
import '../../AdminPanel/Users/userstyle.css';
import Sidebar from '../SideBar/Sidebar.tsx';
import Navbar from '../Navbar/Navbar.tsx';


function Users() {

	const [users, setUsersList] = useState([]);
	const [showAddMode, setShowAddMode] = useState(false);
	const [showEditMode, setShowEditMode] = useState(false);
	const [selectedUserId, setSelectedUserId] = useState(null)
	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		document.body.classList.add('bg-black-dashboard');
		return () => {
			document.body.classList.remove('bg-black-dashboard');
		};
	}, []);

	useEffect(() => {
		if (isActive) {
		  document.body.classList.add('bg-black-darkMode-dashboard');
		  document.body.classList.remove('bg-black-dashboard');
		} else {
			document.body.classList.remove('bg-black-darkMode-dashboard');
			document.body.classList.add('bg-black-dashboard');
		}
	  }, [isActive]);

	const handleChange = () => {
		setIsActive(!isActive);
	}

	useEffect(() => {
		setUsersList(data)
	}, []);

	const getAllUsers = async () => {
		try {
			const response = await axios.get('http://localhost:4000/users');
			if (response) {
				setUsersList(response.data);
			}
		}
		catch (e) {
			console.log(e)
		}
	}

	const actionsTemplate = (rowDate) => {
		return (
			<>
				{/* <button className='btn btn-success' onClick={() => {
                    setSelectedUserId(rowDate.id)
                    setShowViewMode(true)
                }}>
                    <i className='pi pi-eye'></i>
                </button> */}
				<button className='btn btn-primary' title='Edit User' onClick={() => {
					setSelectedUserId(rowDate.id)
					setShowEditMode(true)
				}}>
					<i className='pi pi-file-edit'></i>
				</button>
				<button className='btn btn-danger' title='Delete User' onClick={() => deleteUserConfirm(rowDate.id)}>
					<i className='pi pi-trash'></i>
				</button>
			</>
		)
	}

	const deleteUserConfirm = (userId) => {
		confirmDialog({
			message: 'Are you sure you want to delete this user?',
			header: 'Confirmation',
			// icon: 'pi pi-trash',
			accept: () => deleteUser(userId),
		});
	}

	const deleteUser = async (userId) => {
		try {
			const response = await axios.delete('http://localhost:4000/users/' + userId);
			if (response) {
				getAllUsers();
			}
		}
		catch (e) {
			console.log(e)
		}
	}

	return (
		<>
			<div className='dashboard'>
				<Sidebar isActive={isActive} />
				<div className='dashboardContainer'>
					<Navbar isActive={isActive} handleChange={handleChange} />
					<div className='users-page'>
						<div className='container'>
							<div className='users-list'>
								<div className='addNewUser'>
									<div className='header-users'>
										<h6>Users</h6>
									</div>
									<button className='addUserCls' title='Add User' onClick={() => setShowAddMode(true)}>
										<i className='pi pi-plus' style={{paddingRight:'6px'}}></i> Create User
									</button>
								</div>
								<DataTable value={users}>
									<Column field="name" header="Name"></Column>
									<Column field="username" header="Username"></Column>
									<Column field="email" header="Email Adress"></Column>
									<Column field="phone" header="Phone Number"></Column>
									<Column field="website" header="Website"></Column>
									<Column header="Actions" body={actionsTemplate}></Column>
								</DataTable>
							</div>
						</div>
						{/* <Dialog header="View User Data"
				             visible={showViewMode}
				            style={{ width: '70vw' }}
				            onHide={() => setShowViewMode(false)}>
				           <ViewUser userId={selectedUserId} />
			            </Dialog> */}

						<Dialog header="Add User"
							visible={showAddMode}
							style={{ width: '39vw' }}
							onHide={() => setShowAddMode(false)}>

							<CreateUser setUserAdded={() => {
								setShowAddMode(false);
								getAllUsers();
							}} />
						</Dialog>

						<Dialog header="Edit User"
							visible={showEditMode}
							style={{ width: '39vw' }}
							onHide={() => setShowEditMode(false)}>

							<EditUser visible={showEditMode} userId={selectedUserId} setUserEdited={() => {
								setShowEditMode(false);
								getAllUsers();
							}} />
						</Dialog>

						<ConfirmDialog />

					</div>
				</div>
			</div>

		</>
	)
}

export default Users