import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CreateDistrict(props) {
	const { setGetReloadData } = props;

	const [errorDistrictNameMessage, setErrorDistrictNameMessage] = useState("");
	const [errorCodeMessage, setErrorCodeMessage] = useState("");
	const [errorStateIdMessage, setErrorStateIdMessage] = useState("");

	const [initialValues, setInitialValues] = useState({
		name: '',
		code: '',
		stateId: ''
	});
	const [districtsList, setDistrictsList] = useState([])
	const navigate = useNavigate();
	const apiEndPoint = 'https://ciaitelehealth.com/api';

	useEffect(() => {
		getDistricts();
	}, [])

	const getDistricts = async () => {
		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/districts/get-districts-list`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				//localStorage.setItem("accessToken","");
				//navigate('/login');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setDistrictsList(actualData.data);

			console.log('districtsList', districtsList)
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const validationForm = (type, value) => {

		let errorMessage = "";
		if (type === "name") {
			if (value === "") {
				errorMessage = "Enter District Name ";
			}
			else if (value.length < 3) {
				errorMessage = "Please enter atleast 3 Characters";
			}

			else if (value.length > 10) {
				errorMessage = "Please enter less than 10 Characters only";
			}
			setErrorDistrictNameMessage(errorMessage)
			setAllErrors({ ...allErrors, ['nameErr']: errorMessage });
		}

		if (type === "code") {
			if (value === '') {
				errorMessage = 'Select Code';
			}
			setErrorCodeMessage(errorMessage)
			setAllErrors({ ...allErrors, ['codeErr']: errorMessage })
		}
		if (type === "stateId") {
			if (value === '') {
				errorMessage = 'Select State Name';
			}
			setErrorStateIdMessage(errorMessage)
			setAllErrors({ ...allErrors, ['stateIdErr']: errorMessage })
		}
	}

	const [allErrors, setAllErrors] = useState({
		nameErr: "",
		codeErr: "",
		stateIdErr: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInitialValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
		validationForm(name, value)
	};

	const CreateDistrictData = async () => {

		if (initialValues.name === '' || initialValues.code === "" || initialValues.stateId === ""
		) {
			validationForm('name', initialValues.name);
			validationForm('code', initialValues.code);
			validationForm('stateId', initialValues.stateId);
		} else {
			const url = `${apiEndPoint}/districts/create-district`;

			const formData = {
				name: initialValues.name,
				code: initialValues.code,
				stateId: initialValues.stateId
			};

			const token = localStorage.getItem('accessToken');
			const options = {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			};
			await fetch(url, options)
				.then(response => response.json())
				.then(data => {
					if (data.msg === "reacord created") {
						setInitialValues({
							...initialValues,
							name: '',
							code: '',
							stateId: ''
						});
						setTimeout(() => {
							navigate('/districtsList');
						}, 2000)
						toast.success("District Saved Successfully.");
						props.setDistrictAdd();
						setGetReloadData(true);
					}

				})
				.catch(error => console.error('Error:', error));
		}
	}

	return (
		<div className='user-view _add-view'>
			<div className='box'>
				<div className='row'>
					<div>
						<p>
							<label>District Name</label>
							<input
								type='text'
								className='form-control'
								placeholder='Enter District Name'
								name='name'
								value={initialValues.name}
								onChange={handleChange}
							// onChange={e => setInitialValues({ ...initialValues, name: e.target.value })}
							/>
							<div className="errorSpName">{errorDistrictNameMessage}</div>
						</p>
					</div>

					<div>
						<p>
							<label>Code</label>
							<select
								className="doctorsListCls form-control"
								name='code'
								value={initialValues.code}
								onChange={handleChange}
							// onChange={e => setInitialValues({ ...initialValues, code: e.target.value })}
							>
								<option value="">Select</option>
								{districtsList.map((option, index) => (
									<option
										key={index}
										value={option.code}
									>
										{option.code}
									</option>
								))}
							</select>
							<div className="errorSpName">{errorCodeMessage}</div>
						</p>
					</div>
					<div>
						<p>
							<label>State Name</label>
							<select
								className="doctorsListCls form-control"
								name='stateId'
								value={initialValues.stateId}
								onChange={handleChange}
							// onChange={e => setInitialValues({ ...initialValues, stateId: e.target.value })}
							>
								<option value="">Select</option>
								{districtsList.map((option, index) => (
									<option
										key={index}
										value={option.id}
									>
										{option.name}
									</option>
								))}
							</select>
							<div className="errorSpName">{errorStateIdMessage}</div>
						</p>
					</div>
				</div>
			</div>
			<div className='specialBtnCls'>
				<button type="button" className="button-cancle-cls" style={{ marginRight: '15px' }} onClick={() => props.setDistrictAdd()}>Cancel</button>
				<button type="button" className="button-savecls" onClick={() => CreateDistrictData()}>Save</button>
			</div>
			<ToastContainer />
		</div>
	)
}

export default CreateDistrict;