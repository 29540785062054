import React, { useState, useEffect } from 'react';
import '../../AdminPanel/style.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { parseJSON } from 'date-fns';

import ReviewNotes from './ReviewNotes';
import { Dialog } from 'primereact/dialog';

// import { pdfImage } from '../../../../pdfImage.png';
import pdfImage from '../../TeleHealth/Images/pdfImage.png';
import teleHelath_Img  from '../../TeleHealth/Images/telehealth_Logo.png';


function ReviewDoctor(props) {

	const { updateDoctordata, setGetReloadData } = props;
	const [specializationList, setSpecializationList] = useState([])
	const [QualificationsList, setQualificationList] = useState([])
	const [clinicsList, setClinicsList] = useState([]);

	const [doctorData, setDoctorData] = useState([]);

	const [initialValues, setInitialValues] = useState({
		name: updateDoctordata ? updateDoctordata?.name : '',
		bio: updateDoctordata ? updateDoctordata?.bio : '',
		phoneNumber: updateDoctordata ? updateDoctordata?.phoneNumber : '',
		email: updateDoctordata ? updateDoctordata?.email : '',
		isOfflineConsultationAvailable: false,
		isOnlineConsultationAvailable: false,
		gender: '',
		age: '',
		specializations:[{ id: '', experience: '' }],
		qualifications: [{ id: '', college: '', location: '', fromYear: '', toYear: '' }],
		clinics: [{ id: '' }],
	});
	const [reviewNotesFlag, setReviewNotesFlag] = useState(false);


	const navigate = useNavigate();

	const apiEndPoint = 'https://ciaitelehealth.com/api';

	useEffect(() => {
		const token = localStorage.getItem('accessToken');
		if (!!token) {
			getSpecializations();
			getQualificationsList();
			getClinicsList();

		 let data = localStorage.getItem('doctorDetails');
		 if(data!=="" && data!==null){
		 	data = JSON.parse(data);
		 	setDoctorData(data);
		 	console.log('datadatadatadatadata: ',data);
		 }

		}
	}, []);

	const getSpecializations = async () => {
		try {
			const token = localStorage.getItem('accessToken');

			const response = await fetch(`${apiEndPoint}/specializations/get-specializations-list`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				// localStorage.setItem("accessToken","");
				// navigate('/login');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setSpecializationList(actualData.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const getQualificationsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');

			const response = await fetch(`${apiEndPoint}/qualifications/get-qualifications-list`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				// localStorage.setItem("accessToken","");
				// navigate('/login');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setQualificationList(actualData.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}

	const getClinicsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');

			const response = await fetch(`${apiEndPoint}/clinics/get-clinics-list?search=&page=${1}&pageSize=${10}`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});
			if (!response.ok) {
				// localStorage.setItem("accessToken","");
				// navigate('/login');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setClinicsList(actualData.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	
	
	//Approve status here and navigate liost
	//doctorID={doctorData.id} 

	const approveHandle = async () => { 
		if (doctorData?.id === '' ) {
			//validationForm('name', initialValues.rejectedReason);
		} else {
			//const url = `${apiEndPoint}/doctors/reject-doctor`; 
			const url = `${apiEndPoint}/doctors/approve-doctor`; 
			
			const formData = {
				doctorId: doctorData?.id
			};

			const token = localStorage.getItem('accessToken');
			const options = {
				method: 'PUT',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			};

			await fetch(url, options)
				.then(response => response.json())
				.then(data => {

					console.log('data: ', data);
					if (data.msg === "Doctor Approved Successfully") {
						//setInitialValues({ ...initialValues, rejectedReason: '' });
						setTimeout(() => {
							navigate('/doctorsList');
						}, 2000)
						toast.success("Doctor Approved Successfully.");
						props.setUserEdited();
						setGetReloadData(true);
					} else {
						toast.error("Doctor was already approved.");
						setTimeout(() => {
							navigate('/pendingDoctorsList');
						}, 2000)
					}
				})
				.catch(error => console.error('Error:', error));
		}
	}

	//navigate('/doctorsList');
	

	

	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target;
		setInitialValues(prevState => ({
			...prevState,
			[name]: type === 'checkbox' ? checked : value
		}));
	};

	const handleArrayInputChange = (e, arrayName, index) => {
		const { name, value } = e.target;
		setInitialValues(prevState => ({
			...prevState,
			[arrayName]: [
				...prevState[arrayName].slice(0, index),
				{
					...prevState[arrayName][index],
					[name.split('.')[1]]: value
				},
				...prevState[arrayName].slice(index + 1)
			]
		}));
	};
	return (
		<>


{/* <div className="surface-0">
    
    <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
        <div>
            <div className="font-medium text-3xl text-900">Customers</div>
            <div className="flex align-items-center text-700 flex-wrap">
                <div className="mr-5 flex align-items-center mt-3">
                    <i className="pi pi-users mr-2"></i>
                    <span>332 Active Users</span>
                </div>
                <div className="mr-5 flex align-items-center mt-3">
                    <i className="pi pi-globe mr-2"></i>
                    <span>9402 Sessions</span>
                </div>
                <div className="flex align-items-center mt-3">
                    <i className="pi pi-clock mr-2"></i>
                    <span>2.32m Avg. Duration</span>
                </div>
            </div>
        </div>
        <div className="mt-3 lg:mt-0">
            
        </div>
    </div>
</div> */}
    


<br ></br>
			<div>
				<form className="form-container-doctorView" >

					<div style={{display:'flex'}} width="100%">
						<div width="50%">
							<table width="100%" style={{borderRight: '1px solid #dfd5d5'}}>
								<tr>
									<td width="230px" className='viewPad'><label htmlFor="name">Name</label></td>
									<td width="20px" style={{textAlign:'right', paddingRight:'5px'}}>:</td>
									<td width="230px" style={{textAlign:'left'}}><label>{doctorData.name}</label></td>
								</tr>
								<tr >
									<td width="230px" className='viewPad'><label htmlFor="bio">Bio</label></td>
									<td width="20px" style={{textAlign:'right',paddingRight:'5px'}}>:</td>
									<td width="230px" style={{textAlign:'left'}}><label>{doctorData.bio}</label></td>
								</tr>
								<tr >
									<td width="230px" className='viewPad'><label htmlFor="phonenumber">Phone Number</label></td>
									<td width="20px" style={{textAlign:'right',paddingRight:'5px'}}>:</td>
									<td width="230px" style={{textAlign:'left'}}><label>{doctorData.phoneNumber}</label></td>
								</tr>
								<tr >
									<td width="230px" className='viewPad'><label htmlFor="email">Email</label></td>
									<td width="20px" style={{textAlign:'right',paddingRight:'5px'}} >:</td>
									<td width="230px" style={{textAlign:'left'}}><label>{doctorData.email}</label></td>
								</tr>
								<tr >
									<td width="230px" className='viewPad'><label htmlFor="OfflineConsultationAvailable">Offline Consultation Available</label></td>
									<td width="20px" style={{textAlign:'right'}} className='viewPad'>:</td>
									<td width="230px" style={{textAlign:'left',paddingRight:'5px'}} className='viewPad'><label>{doctorData.isOfflineConsultationAvailable}</label></td>
								</tr>

								<tr>
									<td width="230px" className='viewPad'><label htmlFor="OnlineConsultationAvailable">Online Consultation Available</label></td>
									<td width="20px" style={{textAlign:'right',paddingRight:'5px'}}>:</td>
									<td width="230px" style={{textAlign:'left'}}><label>{doctorData.isOnlineConsultationAvailable}</label></td>
								</tr>
								<tr>
									<td width="230px" className='viewPad'><label htmlFor="consultationFee">Consultation Fee</label></td>
									<td width="20px" style={{textAlign:'right',paddingRight:'5px'}}>:</td>
									<td width="230px" style={{textAlign:'left'}}><label>{doctorData.consultationFee}</label></td>
								</tr>
								<tr>
									<td width="230px" className='viewPad'><label htmlFor="gender">Gender</label></td>
									<td width="20px" style={{textAlign:'right',paddingRight:'5px'}}>:</td>
									<td width="230px" style={{textAlign:'left'}}><label>{doctorData.gender}</label></td>
								</tr>
								<tr>
									<td width="230px" className='viewPad'><label htmlFor="gender">Age</label></td>
									<td width="20px" style={{textAlign:'right',paddingRight:'5px'}}>:</td>
									<td width="230px" style={{textAlign:'left'}}><label>{doctorData.age}</label></td>
								</tr>

							</table>
						</div>
						<div width="50%">
							<table width="100%">
								<tr >
									<td width="150px" className='viewPad'><label htmlFor="name">Certificates</label></td>
									<td width="20px" style={{textAlign:'right', paddingRight:'5px'}}>:</td>
									<td width="230px" style={{textAlign:'left'}}>
										<label>
										{/* <embed src="file_name.pdf" width="100px" height="100px" /> */}
										<iframe src="https://media.geeksforgeeks.org/wp-content/cdn-uploads/20210101201653/PDF.pdf" style={{width: '100px',height: '100px',border: 'none'}}></iframe>
										</label>
									</td>
								</tr>
								<tr>
									<td width="150px" className='viewPad'><label htmlFor="bio">PDFs</label></td>
									<td width="20px" style={{textAlign:'right',paddingRight:'5px'}}>:</td>
									<td width="230px" style={{textAlign:'left'}}><label>
									<img src={pdfImage} border='1px solid #e2dfdf;' alt="Girl in a jacket" width="100" height="100" />
									{/* <img width='210' height='90' src='./telehealth_Logo.png' alt='Logo' /> */}
									</label>
									</td>
								</tr>
							</table>
						</div> 

					</div>


					<div className="button-container">
						<button className='button-savecls' type="button" 
						//onClick={reviewDoctorData}

						onClick={() => {
							//setSelectedUserId(doctorData)
							setReviewNotesFlag(true)		
						}} 
						
						>Reject</button>&nbsp;&nbsp;

						{/* <Button style={{ background: '#0b7c69', color: '#fff' }} icon="pi pi-pencil" title='Edit' rounded outlined className="mr-2" onClick={() => {
					setSelectedUserId(rowData)
					setShowEditMode(true)

				}} /> */}

						
						<button className='button-cancle-cls' type="button" onClick={approveHandle}>Approve</button>
					</div>
					<ToastContainer />

					<Dialog header="Review Doctor Details"
						visible={reviewNotesFlag}
						style={{ width: '39vw' }}
						onHide={() => setReviewNotesFlag(false)}>

						<ReviewNotes visible={reviewNotesFlag} doctorID={doctorData.id} setUserEdited={() => {
							setReviewNotesFlag(false);
							//getAllUsers();
						}} />
					</Dialog>
				</form>
			</div>
		</>
	);
}

export default ReviewDoctor;