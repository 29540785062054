import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputSwitch } from 'primereact/inputswitch';

function UpdateSachivalayam(props) {
	const { updateData, setGetReloadData, selectedRowId } = props;
	const updateStatus = updateData && updateData?.status === 'Active' ? true :
		updateData && updateData?.status === 'In-Active' ? false : false;

	const [errorSachiavalayamNameMessage, setErrorSachiavalayamNameMessage] = useState("");
	// const [errorCodeMessage, setErrorCodeMessage] = useState("");
	const [errorMandalIdMessage, setErrorMandalIdMessage] = useState("");


	const [initialValues, setInitialValues] = useState({
		name: updateData ? updateData?.name : '',
		code: updateData ? updateData?.code : '',
		mandalId: updateData ? updateData?.mandalId : '',
	});
	console.log('updateData', updateData)
	const [data, setData] = useState([]);


	const [questStatus, setquestStatus] = useState(updateStatus);
	const navigate = useNavigate();
	const apiEndPoint = 'https://ciaitelehealth.com/api';

	useEffect(() => {
	    getMandalsList();
	}, [])


	const getMandalsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');

			const response = await fetch(`${apiEndPoint}/mandals/get-mandals-list`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				// localStorage.setItem("accessToken","");
				// navigate('/login');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setData(actualData.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const validationForm = (type, value) => {

		let errorMessage = "";
		if (type === "name") {
			if (value === "") {
				errorMessage = "Enter Sachiavalayam Name ";
			}
			else if (value.length < 3) {
				errorMessage = "Please enter atleast 3 Characters";
			}

			else if (value.length > 20) {
				errorMessage = "Please enter less than 20 Characters only";
			}
			setErrorSachiavalayamNameMessage(errorMessage)
			setAllErrors({ ...allErrors, ['nameErr']: errorMessage });
		}

		// if (type === "code") {
		// 	if (value === '') {
		// 		errorMessage = 'Select Code';
		// 	}
		// 	setErrorCodeMessage(errorMessage)
		// 	setAllErrors({ ...allErrors, ['codeErr']: errorMessage })
		// }

		if (type === "mandalId") {
			if (value === '') {
				errorMessage = 'Select Mandal Name';
			}
			setErrorMandalIdMessage(errorMessage)
			setAllErrors({ ...allErrors, ['mandalIdErr']: errorMessage })
		}

	}

	const [allErrors, setAllErrors] = useState({
		nameErr: "",
		// codeErr: "",
		mandalIdErr: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInitialValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
		validationForm(name, value)
	};


	const UpdateSachivalayamData = async () => {

		if (initialValues.name === '' || initialValues.mandalId === '' 
			// || initialValues.code === ""
		) {
			validationForm('name', initialValues.name);
			// validationForm('code', initialValues.code);
			validationForm('mandalId', initialValues.mandalId);

		} else {
			const url = `${apiEndPoint}/sachivalayams/update-sachivalayam`;
			const formData = {
				id: updateData?.id,
				name: initialValues.name,
			    code: initialValues.code,
				mandalId: initialValues.mandalId,
				status: questStatus === true ? 'Active' : 'In-Active'
			};

			const token = localStorage.getItem('accessToken');
			const options = {
				method: 'PUT',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			};

			await fetch(url, options)
				.then(response => response.json())
				.then(data => {
					// console.log('data1', data.msg )
					if (data.msg === "Sachivalayam information updated successfully") {
						setInitialValues({ ...initialValues, 
							name: '', 
							code: '', 
							mandalId: '', 
						});
						setTimeout(() => {
							navigate('/sachivalayamsList');
						}, 2000)
						toast.success("Sachivalayam information updated successfully");
						props.setUserEdited();
						setGetReloadData(true);
					}
				})
				.catch(error => console.error('Error:', error));
		}
	}
 
	const handleChangeSwitch = (e) => {
		setquestStatus(!questStatus);
	};

	return (
		<div className='user-view _add-view'>
			<div className='box'>
				<div className='row'>
					<div>
						<p>
							<span>Sachivalayam Name</span>
							<input
								type='text'
								className='form-control'
								placeholder='Enter District Name'
								name='name'
								value={initialValues.name}
								onChange={handleChange}
							// onChange={e => setInitialValues({ ...initialValues, name: e.target.value })}
							/>
							<div className="errorSpName">{errorSachiavalayamNameMessage}</div>
						</p>
					</div>

					{/* <div>
						<p>
							<label>Code</label>
							<select
								className="doctorsListCls form-control"
								name='code'
								value={initialValues.code}
								disabled={updateData?.code}
								
							    onChange={e => setInitialValues({ ...initialValues, code: e.target.value })}
							>
								<option value="">Select</option>
								{sachivalayamList.map((option, index) => (
									<option
										key={index}
										value={option.code}
									>
										{option.code}
									</option>
								))}
							</select>
							 <div className="error">{errorCodeMessage}</div> 
						</p>
					</div> */}

					<div>
						<p>
							<label>Mandal Name</label>
							<select
								className="doctorsListCls form-control"
								name='mandalId'
								value={initialValues.mandalId}
								onChange={handleChange}
							// onChange={e => setInitialValues({ ...initialValues, mandalId: e.target.value })}
							>
								<option value="">Select</option>
								{data.map((option, index) => (
									<option
										key={index}
										value={option.id}
									>
										{option.name}
									</option>
								))}
							</select>
							<div className="errorSpName">{errorMandalIdMessage}</div>
						</p>
					</div>

					<div style={{ display: 'flex' }}>
						<div style={{ paddingLeft: '2px' }}>Status</div>
						<div style={{ paddingLeft: '10px' }}><InputSwitch inputId="input-metakey" checked={questStatus} onChange={handleChangeSwitch} /></div>
					</div>




				</div>
			</div>
			<div className='specialBtnCls'>
				<button type="button" className="button-cancle-cls" style={{ marginRight: '15px' }} onClick={() => props.setUserEdited()}>Cancel</button>
				<button type="button" className="button-savecls" onClick={() => UpdateSachivalayamData()}>Update</button>
			</div>
			<ToastContainer />
		</div>
	)
}

export default UpdateSachivalayam;