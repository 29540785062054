import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import '../../TeleHealth/TeleHealth.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CreateClinics(props) {
	const { setGetReloadData } = props;

	const [errorNameMessage, setErrorNameMessage] = useState("");
	const [errorAddress1Message, setErrorAddress1Message] = useState("");
	const [errorAddress2Message, setErrorAddress2Message] = useState("");
	const [errorCityMessage, setErrorCityMessage] = useState("");
	const [errorStateMessage, setErrorStateMessage] = useState("");
	const [errorZipcodeMessage, setErrorZipcodeMessage] = useState("");
	const [errorContactNumber1Message, setErrorContactNumber1Message] = useState("");
	const [errorContactNumber2Message, setErrorContactNumber2Message] = useState("");
	const [errorContactNameMessage, setErrorContactNameMessage] = useState("");

	const [initialValues, setInitialValues] = useState({
		name: '',
		address1: '',
		address2: '',
		city: '',
		state: '',
		zipcode: '',
		contactNumber1: '',
		contactNumber2: '',
		contactName: '',
	});

	// const handleInputChange = (e) => {
	// 	const { name, value } = e.target;
	// 	setInitialValues({ ...initialValues, [name]: value });
	// };


	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target;
		setInitialValues(prevState => ({
			...prevState,
			[name]: type === 'checkbox' ? checked : value

		}));
		validationForm(name, value)
	};

	const validationForm = (type, value) => {

		let errorMessage = "";
		if (type === "name") {
			if (value === "") {
				errorMessage = "Enter the Name ";
			}
			else if (value.length < 3) {
				errorMessage = "Please enter atleast 3 Characters";
			}

			else if (value.length > 20) {
				errorMessage = "Please enter less than 20 Characters only";
			}
			setErrorNameMessage(errorMessage)
			setAllErrors({ ...allErrors, ['nameErr']: errorMessage });
		}
		if (type === "address1") {
			if (value === "") {
				errorMessage = "Enter the Address ";
			}
			else if (value.length < 10) {
				errorMessage = "Please enter atleast 10 Characters";
			}

			else if (value.length > 70) {
				errorMessage = "Please enter less than 70 Characters only";
			}
			setErrorAddress1Message(errorMessage)
			setAllErrors({ ...allErrors, ['address1Err']: errorMessage });
		}
		if (type === "address2") {
			if (value === "") {
				errorMessage = "Enter the Address ";
			}
			else if (value.length < 10) {
				errorMessage = "Please enter atleast 10 Characters";
			}

			else if (value.length > 60) {
				errorMessage = "Please enter less than 60 Characters only";
			}
			setErrorAddress2Message(errorMessage)
			setAllErrors({ ...allErrors, ['address2Err']: errorMessage });
		}
		if (type === "city") {
			if (value === "") {
				errorMessage = "Enter the City ";
			}
			else if (value.length < 3) {
				errorMessage = "Please enter atleast 3 Characters";
			}

			else if (value.length > 20) {
				errorMessage = "Please enter less than 20 Characters only";
			}
			setErrorCityMessage(errorMessage)
			setAllErrors({ ...allErrors, ['cityErr']: errorMessage });
		}
		if (type === "state") {
			if (value === "") {
				errorMessage = "Enter the State ";
			}
			else if (value.length < 3) {
				errorMessage = "Please enter atleast 3 Characters";
			}

			else if (value.length > 20) {
				errorMessage = "Please enter less than 20 Characters only";
			}
			setErrorStateMessage(errorMessage)
			setAllErrors({ ...allErrors, ['stateErr']: errorMessage });
		}
		if (type === "zipcode") {
			if (validateNum(value) === "Empty") {
				errorMessage = "Enter the Zip Code";
			}
			else if (validateNum(value) === 'NotValid') {
				errorMessage = "Zip Code length shuld be  Only 6 Characters";
			} else if (validateNum(value) === true) {
				errorMessage = "Only Numberic Chracters are allowed";
			}
			setErrorZipcodeMessage(errorMessage);
			setAllErrors({ ...allErrors, ['zipcodeErr']: errorMessage });
		}
		if (type === 'contactNumber1') {
			if (value === '') {
				errorMessage = "Enter Contact Number";
			}
			else if (ValiadateMobileNum(value) === true) {
				errorMessage = "Enter Valid Contact Number";
			}
			setErrorContactNumber1Message(errorMessage);
			setAllErrors({ ...allErrors, ['contactNumber1Err']: errorMessage });
		}
		if (type === 'contactNumber2') {
			if (value === '') {
				errorMessage = "Enter Contact Number";
			}
			else if (ValiadateMobileNum(value) === true) {
				errorMessage = "Enter Valid Contact Number";
			}
			setErrorContactNumber2Message(errorMessage);
			setAllErrors({ ...allErrors, ['contactNumber2Err']: errorMessage });
		}
		if (type === "contactName") {
			if (value === "") {
				errorMessage = "Enter the Contact Name ";
			}
			else if (value.length < 3) {
				errorMessage = "Please enter atleast 3 Characters";
			}

			else if (value.length > 20) {
				errorMessage = "Please enter less than 20 Characters only";
			}
			setErrorContactNameMessage(errorMessage)
			setAllErrors({ ...allErrors, ['contactNameErr']: errorMessage });
		}
	}

	function validateNum(value) {
		var patForReqdFld = /^(\-)?([\d]+(?:\.\d{1,2})?)$/;

		if (value === "") {
			return 'Empty';
		}
		else if (!patForReqdFld.test(value)) {
			return true;
		}
		else if (value.length !== 6) {
			return 'NotValid';
		}
		return;
	}

	function ValiadateMobileNum(value) {
		var phoneVal = /^[0-9]{10}$/g;
		if (!phoneVal.test(value)) {
			return true;
		}
	}

	const [allErrors, setAllErrors] = useState({
		nameErr: "",
		address1Err: "",
		address2Err: "",
		cityErr: "",
		stateErr: "",
		zipcodeErr: "",
		contactNumber1Err: "",
		contactNumber2Err: "",
		contactNameErr: ""
	});

	const navigate = useNavigate();
	const apiEndPoint = 'https://ciaitelehealth.com/api';

	const CreateClinicsData = async () => {
		// e.preventDefault();

		if (initialValues.name === '' || initialValues.address1 === "" || initialValues.address2 === "" || initialValues.city === "" ||
			initialValues.state === "" || initialValues.zipcode === "" || initialValues.contactNumber1 === ""
			|| initialValues.contactNumber2 === "" || initialValues.contactName === ""
		) {
			validationForm('name', initialValues.name);
			validationForm('address1', initialValues.address1);
			validationForm('address2', initialValues.address2);
			validationForm('city', initialValues.city);
			validationForm('state', initialValues.state);
			validationForm('zipcode', initialValues.zipcode);
			validationForm('contactNumber1', initialValues.contactNumber1);
			validationForm('contactNumber2', initialValues.contactNumber2);
			validationForm('contactName', initialValues.contactName);

		} else {

			const url = `${apiEndPoint}/clinics/create-clinic`;

			const formData = {
				name: initialValues.name,
				address1: initialValues.address1,
				address2: initialValues.address2,
				city: initialValues.city,
				state: initialValues.state,
				zipcode: initialValues.zipcode,
				contactNumber1: initialValues.contactNumber1,
				contactNumber2: initialValues.contactNumber2,
				contactName: initialValues.contactName,

			};
			const token = localStorage.getItem('accessToken');
			const options = {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			};
			console.log("Create data: ", formData);

			await fetch(url, options)
				.then(response => response.json())
				.then(data => {
					if (data.msg === "Clinic created successfully") {
						setInitialValues({
							...initialValues,
							name: '',
							address1: '',
							address2: '',
							city: '',
							state: '',
							zipcode: '',
							contactNumber1: '',
							contactNumber2: '',
							contactName: '',
						});
						setTimeout(() => {
							navigate('/ClinicsList');
						}, 2000)
						toast.success("Clinic created successfully");
						props.setUserAdded();
						setGetReloadData(true)
					}
				})
				.catch(error => console.error('Error:', error));
		}
	}

	// const handleReset = () => {
	// 	setInitialValues({
	// 		name: '',
	// 		address1: '',
	// 		address2: '',
	// 		city: '',
	// 		state: '',
	// 		zipcode: '',
	// 		contactNumber1: '',
	// 		contactNumber2: '',
	// 		contactName: '',
	// 	});
	// 	setAllErrors({
	// 		nameErr: "",
	// 		address1Err: "",
	// 		address2Err: "",
	// 		cityErr: "",
	// 		stateErr: "",
	// 		zipcodeErr: "",
	// 		contactNumber1Err: "",
	// 		contactNumber2Err: "",
	// 		contactNameErr: ""

	// 	});
	// 	setErrorNameMessage("");
	// 	setErrorAddress1Message("");
	// 	setErrorAddress2Message("");
	// 	setErrorCityMessage("");
	// 	setErrorStateMessage("");
	// 	setErrorZipcodeMessage("");
	// 	setErrorContactNumber1Message("");
	// 	setErrorContactNumber2Message("");
	// 	setErrorContactNameMessage("");
	// };

	return (
		<div className='user-view _add-view'>
			<div className='box'>
				<div className='row'>
					<div>
						<form
						// onSubmit={handleSubmit}
						>
							<div className="form-group">
								<label>Name</label>
								<input type="text" className="form-control" placeholder="Enter the Name" name="name" value={initialValues.name} onChange={handleInputChange} required />
								<div className="error">{errorNameMessage}</div>
							</div><br />
							<div className="form-group">
								<label>Address1</label>
								<input type="text" className="form-control" placeholder="Enter the Address1" name="address1" value={initialValues.address1} onChange={handleInputChange} required />
								<div className="error">{errorAddress1Message}</div>
							</div><br />
							<div className="form-group">
								<label>Address2</label>
								<input type="text" className="form-control" placeholder="Enter the Address2" name="address2" value={initialValues.address2} onChange={handleInputChange} required />
								<div className="error">{errorAddress2Message}</div>
							</div><br />
							<div className="form-group">
								<label>City</label>
								<input type="text" className="form-control" placeholder="Enter the City" name="city" value={initialValues.city} onChange={handleInputChange} required />
								<div className="error">{errorCityMessage}</div>
							</div><br />
							<div className="form-group">
								<label>State</label>
								<input type="text" className="form-control" placeholder="Enter the State" name="state" value={initialValues.state} onChange={handleInputChange} required />
								<div className="error">{errorStateMessage}</div>
							</div><br />
							<div className="form-group">
								<label>Zip Code</label>
								<input type="text" className="form-control" placeholder="Enter the Zipcode" name="zipcode" value={initialValues.zipcode} onChange={handleInputChange} required />
								<div className="error">{errorZipcodeMessage}</div>
							</div><br />
							<div className="form-group">
								<label>Contact Number1</label>
								<input type="text" className="form-control" placeholder="Enter the Contact Number1" name="contactNumber1" value={initialValues.contactNumber1} onChange={handleInputChange} required />
								<div className="error">{errorContactNumber1Message}</div>
							</div><br />
							<div className="form-group">
								<label>Contact Number2</label>
								<input type="text" className="form-control" placeholder="Enter the Contact Number2" name="contactNumber2" value={initialValues.contactNumber2} onChange={handleInputChange} required />
								<div className="error">{errorContactNumber2Message}</div>
							</div><br />
							<div className="form-group">
								<label>Contact Name</label>
								<input type="text" className="form-control" placeholder="Enter the Contact Name" name="contactName" value={initialValues.contactName} onChange={handleInputChange} required />
								<div className="error">{errorContactNameMessage}</div>
							</div><br />

							<button type="button" className="button-savecls" style={{ float: 'right', marginTop: '-10px' }} onClick={() => CreateClinicsData()}>Save</button>
							<button type="button" className="button-cancle-cls" style={{ float: 'right', marginTop: '-10px', marginRight: '12px' }} onClick={() => props.setUserAdded()}>Cancel</button>
						</form>
					</div>
				</div>
			</div>
			<ToastContainer />
		</div>
	)
}

export default CreateClinics;