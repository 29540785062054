import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import Sidebar from '../../AdminPanel/SideBar/Sidebar.tsx';
import Navbar from '../../AdminPanel/Navbar/Navbar.tsx';
import CreateSpecialization from './CreateSpecialization.js';
import UpdateSpecialization from './UpdateSpecialization.js';
import '../TeleHealth.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import NoImage from '../../TeleHealth/Images/NoImage.png';

function SpecializationsList() {
	const  UAToken =  localStorage.getItem("accessToken");
	const navigate = useNavigate();

	const [data, setData] = useState([]);
	const [showAddMode, setShowAddMode] = useState(false);
	const [showEditMode, setShowEditMode] = useState(false);
	const [rowItem,setRowItem] = useState(null);
	const [isActive, setIsActive] = useState(false);
	const [getReloadData,setGetReloadData]= useState(false);

	console.log('UAToken:  11111111111111');


	const getSeverity = (status) => {
		switch (status) {
			case 'Active':
				return 'success';
			case 'In-Active':
				return 'danger';
		}
	};

	const apiEndPoint = 'https://ciaitelehealth.com/api';
	
	useEffect(() => {
		document.body.classList.add('bg-black-dashboard');
		return () => {
			document.body.classList.remove('bg-black-dashboard');
		};
	}, []);
	

	useEffect(() => {
		if (getReloadData) {
			getSpecializations();
			setGetReloadData(false);
		}
	}, [getReloadData])



	useEffect(() => {
		if (isActive) {
		  document.body.classList.add('bg-black-darkMode-dashboard');
		  document.body.classList.remove('bg-black-dashboard');
		} else {
			document.body.classList.remove('bg-black-darkMode-dashboard');
			document.body.classList.add('bg-black-dashboard');
		}
	  }, [isActive]);

	const handleChange = () => {
		setIsActive(!isActive);
	}

	useEffect(() => {

		console.log('UAToken: ', UAToken);


		if (UAToken && UAToken !== "" && UAToken !== null) {
			navigate('/specializationsList');
		}
		else {
			navigate('/')
		}
	}, [UAToken]);
	

	useEffect(() => {
		console.log('response top in token :');

		const token = localStorage.getItem('accessToken');
		if(!!token){

			console.log('response top in :', token);

			getSpecializations();
		} else {
			console.log('response top:', token);

		}
	}, []);
	
	const getSpecializations = async () => {
		try {
			const token = localStorage.getItem('accessToken');
	
			const response = await fetch(`${apiEndPoint}/specializations/get-specializations-list`, {
				method: 'GET', 
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});
	
			if (!response.ok) {

				console.log('response:', response);
				// localStorage.setItem("accessToken","");
				// navigate('/login');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setData(actualData.data);
		} catch (error) {
			navigate('/login');

			console.error('Error fetching data:', error);
		}
	};
	
	const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" style={{ background: '#0b7c69', color: '#fff'}} title='Edit' rounded outlined className="mr-2" onClick={() => {
                    	setRowItem(rowData)
						setShowEditMode(true)
                }}/>
                <Button icon="pi pi-trash" className="deleteBtnFocus"  title='Delete' rounded outlined severity="danger" onClick={() => deleteConfirm(rowData.id,rowData)} />
				{/* style={{ background: '#4CAF50', color: '#fff'}} */}
            </React.Fragment>
        );
    };

	const statusBodyTemplate = (data) => {
		return <Tag value={data.status} severity={getSeverity(data.status)}></Tag>;
	};

	const imageBodyTemplate = (data) =>{
		return <img  height={80} width={80}
		//src="https://ciaith.s3.ap-south-1.amazonaws.com/specialities/ayurveda.png" 
		src={data.image !==null ? data.image : NoImage}
		alt={"Carlie Anglemire"}/>
   	}

	const deleteConfirm = (userId) => {
		confirmDialog({
			message: 'Are you sure you want to delete this Specialization?',
			header: 'Confirmation',
			accept: () => deleteSpecialization(userId),
		});
	}

	const deleteSpecialization = async (Id) => {
		try {
			const token = localStorage.getItem('accessToken');
			await fetch(`${apiEndPoint}/specializations/delete-specialization/${Id}`, {
				method: 'DELETE',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			  })
			  .then(response => response.json())
			  .then(actualData => {
				console.log('actualData ', actualData);
				toast.success("Specialization Delete Successfully.");
				getSpecializations();
			  })
			  .catch(err => {
				console.log(err.message);
			  });
		}
		catch (e) {
			console.log(e)
		}
	}

	return (
		<>
			<div className='dashboard'>
				<Sidebar isActive={isActive} />
				<div className='dashboardContainer'>
					<Navbar isActive={isActive} handleChange={handleChange} />
					<div className='users-page'>
						<div className='container'>
							<div className='users-list'>
								<div className='addNewUser'>
									<div className='header-users'>
										<h6>Specializations</h6>
									</div>
									<button className='addUserCls' title='Add User' onClick={() => setShowAddMode(true)}>
										<i className='pi pi-plus' style={{paddingRight:'6px'}}></i> Create
									</button>
								</div>
								<DataTable value={data} paginator rows={10}>
									<Column style={{ width: '50%' }} field="specialization" header="Specialization" sortable filter></Column>
									<Column style={{ width: '15%' }} field="image" header="Image"  body={imageBodyTemplate} sortable filter></Column>
									<Column style={{ width: '15%' }} header="Status" body={statusBodyTemplate} sortable filter></Column>
									<Column style={{ width: '15%' }} header="Actions" body={actionBodyTemplate} sortable filter></Column>
								</DataTable>
							</div>
						</div>
						<Dialog header="Add Specialization"
							visible={showAddMode}
							style={{ width: '39vw' }}
							onHide={() => setShowAddMode(false)}>

							<CreateSpecialization setGetReloadData={setGetReloadData} setUserAdded={() => {
								setShowAddMode(false);
								//getSpecializations()
							}} />
						</Dialog>

						<Dialog header="Edit Specialization"
							visible={showEditMode}
							style={{ width: '39vw' }}
							onHide={() => setShowEditMode(false)}>

							<UpdateSpecialization visible={showEditMode} setGetReloadData={setGetReloadData} updateData={rowItem} setUserEdited={() => {
								setShowEditMode(false);
								//getSpecializations();
							}} />
						</Dialog>

						<ConfirmDialog />
						<ToastContainer/>

					</div>
				</div>
			</div>

		</>
	)
}

export default SpecializationsList



