import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Login.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import Alert from '@mui/material/Alert';
import { InputOtp } from 'primereact/inputotp';
export default function Login() {
	const UAToken = localStorage.getItem("accessToken");
	const [token, setTokens] = useState();
	const [initialValues, setInitialValues] = useState({
		phoneNumber: '',
		password: '',
		otp: ''
	})
	const [data, setData] = useState("");
	const [isOtpSent, setIsOtpSent] = useState(false);

	const apiEndPoint = 'https://ciaitelehealth.com/api';

	const navigate = useNavigate();

	console.log("UAToken: ", UAToken);

	useEffect(() => {
		if (UAToken && UAToken !== "" && UAToken !== null) {
			navigate('/dashboard');
		}
	}, [UAToken]);

	//   // Set body style
	useEffect(() => {
		document.body.classList.add('bodyContainer_Cls');
		return () => {
			document.body.classList.remove('bodyContainer_Cls');
		};
	}, []);

	const handleChange = (e) => {
		console.log('Event:', e);
		console.log('Event target:', e.target);
		const name = e.target.name;
		const value = e.target.value;
		console.log(`Changing ${name} to ${value}`);
		setInitialValues({ ...initialValues, [name]: value });
	}


	function verifyPhoneNumber(value) {
		//var regx = /^[6-9]\d{9}$/;
		//var regx = /^[0-9]+$/; 
		var regx = /^[0-9 _]*[0-9 _]*[0-9]+$/
		if (value === '') {
			return 'phoneNumberEmpty';
		}
		if (!regx.test(value))
			return true;		
		else
			return false;
	}

	const verifyAdmin = () => {
		const formData = {
			phoneNumber: initialValues.phoneNumber,
		};

		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(formData),
		};
		const url = `/login/verify-admin`;
		try {
			const res = fetch(url, options);
			if (!!res) {
				setInitialValues({
					...initialValues,
					phoneNumber: '',
				});
				setTimeout(() => {
					navigate('/dashboard');
				}, 1000)
				toast.success("User Login Successfully.");
			}
			console.log('response ', res);
		} catch (error) {
			console.error('Error:', error);
		}
	}
	const sendOtp = () => {
		const formData = {
			phoneNumber: initialValues.phoneNumber,
		};

		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(formData),
		};
		const url = `${apiEndPoint}/login/send-otp`;
		try {
			const res = fetch(url, options);
			if (!!res) {
				toast.success("OTP Sent Successfully.");
				setIsOtpSent(true);
			}
			console.log('response ', res);
		} catch (error) {
			console.error('Error:', error);

		}
	};

	const adminValidate_OTP = () => {
        const formData = {
            phoneNumber: initialValues.phoneNumber,
            otp: initialValues.otp,
        };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        };
        const url = `${apiEndPoint}/login/admin-validate-otp`;
        fetch(url, options)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    toast.success("OTP Validated Successfully.");
                } else {
                    toast.error("Failed to Validate OTP.");
                }
            })
            .catch(error => console.error('Error:', error));
    };





	// const adminValidate_OTP = () => {
	// 	const formData = {
	// 		phoneNumber: initialValues.phoneNumber,
	// 		otp: initialValues.otp,
	// 	};

	// 	const options = {
	// 		method: 'POST',
	// 		headers: {
	// 			'Content-Type': 'application/json',
	// 		},
	// 		body: JSON.stringify(formData),
	// 	};
	// 	const url = `/login/admin-validate-otp`;
	// 	try {
	// 		const res = fetch(url, options);
	// 		if (!!res) {
	// 			setInitialValues({
	// 				...initialValues,
	// 				phoneNumber: '',
	// 				otp: ''
	// 			});
	// 			// setTimeout(() => {
	// 			// 	navigate('/dashboard');
	// 			// }, 1000)
	// 			// toast.success("User Login Successfully.");
	// 		}
	// 		console.log('response ', res);
	// 	} catch (error) {
	// 		console.error('Error:', error);
	// 	}
	// }

	const adminResetOTP = () => {
		const formData = {
			phoneNumber: initialValues.phoneNumber,
		};

		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(formData),
		};
		const url = `login/admin-resend-otp`;
		try {
			const res = fetch(url, options);
			if (!!res) {
				setInitialValues({
					...initialValues,
					phoneNumber: '',
				});
				setTimeout(() => {
					navigate('/dashboard');
				}, 1000)
				toast.success("User Login Successfully.");
			}
			console.log('response ', res);
		} catch (error) {
			console.error('Error:', error);
		}
	}

	const admin_CreatePassword = () => {
		const formData = {
			phoneNumber: initialValues.phoneNumber,
			password: initialValues.password,
		};

		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(formData),
		};
		const url = `/login/create-password`;
		try {
			const res = fetch(url, options);
			setInitialValues({
				...initialValues,
				phoneNumber: '',
				password: ''
			});
			console.log('response ', res);
			setTimeout(() => {
				navigate('/dashboard');
			}, 1000)
			toast.success("User Login Successfully.");
		} catch (error) {
			console.error('Error:', error);
		}
	}

	function handleSubmit(event) {
		event.preventDefault();

		if (verifyPhoneNumber(initialValues.phoneNumber) === 'phoneNumberEmpty') {
			toast.error('Please Enter Mobile Number')
		}
		else if (verifyPhoneNumber(initialValues.phoneNumber) === true) {
			toast.error('Please Enter Valid Mobile Number')
		}
		else if (initialValues.password === '') {
			toast.error('Please Enter Password')

		}
		else if (initialValues.password.length !== 6) {
			toast.error('Password Length Should be 6 Characters Only.')
		}

		// else if (initialValues.otp === '') {
		// 	toast.error('Please Enter otp')

		// }
		// else if (initialValues.otp.length !== 4) {
		// 	toast.error('Password Length Should be 4 Characters Only.')
		// }
		else {

			const formData = {
				phoneNumber: initialValues.phoneNumber,
				password: initialValues.password,
				otp: initialValues.otp,
			};

			const options = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			};
			const url = `${apiEndPoint}/login/admin-login`;
			fetch(url, options)
				.then(response => response.json())
				.then(data => {
					localStorage.setItem("accessToken", data.token);
					localStorage.setItem("messageStatus", data.msg);
					localStorage.setItem("userRole", data.role);
					if (data.msg === "success") {
						toast.success("User Login Successfully.");
						setInitialValues({ ...initialValues, phoneNumber: '', password: '', otp: '', });
						setTimeout(() => {
							navigate('/dashboard');
						}, 1000)
					} else {
						toast.error("Please Enter Valid User.");
					}
				})
				.catch(error => console.error('Error:', error));
		}
	}

	return (
		<div>
			<div className='containeralignCls right-panel-active' id='container'>
				<div className='form-container sign-in-container'>
					<form className='formAlignCls'>
						<h1 className='headerAlignCls'>USER LOGIN</h1>
						<input
							className='inputFieldAlignCls'
							type='text'
							placeholder='PhoneNumber'
							name='phoneNumber'
							autoComplete='off'
							//maxLength={10}
							maxLength={13}
							value={initialValues.phoneNumber}
							onChange={handleChange}
						/>
						<input
							className='inputFieldAlignCls'
							type='password'
							name='password'
							placeholder='Password'
							autoComplete='off'
							maxLength={6}
							value={initialValues.password}
							onChange={handleChange}
						/><br />

{/* 
						{isOtpSent && (
							<InputOtp
								className='inputFieldAlignCls'
								name='otp'
								placeholder='OTP'
								autoComplete='off'
								maxLength={4}
								value={initialValues.otp}
								onChange={(e) => handleChange({ target: { name: 'otp', value: e.value } })}
							/>

						)}<br />

						<div>
							{isOtpSent ? (
								<button type='button' className='buttonAlignCls' onClick={adminValidate_OTP}>Validate OTP</button>
							) : (<button type='button' className='buttonAlignOtpCls' onClick={sendOtp}>Send OTP</button>
							)}
						</div> <br />

 */}


						<div><button type='button' className='buttonAlignCls' value='submit' onClick={handleSubmit}>Sign In</button></div>
						<div>
							<button type='button' className='forgotPasswordLink' onClick={() => navigate('/forget-password')}>Forgot Password?</button>
						</div>

					</form>
				</div>

				<div className='overlay-container'>
					<div className='overlay'>
						<div className='overlay-panel overlay-left'>
							<div className='ml-4'>
								<img width='210' height='90' src='./telehealth_Logo.png' />
							</div>&nbsp;&nbsp;
							<h1 className='headerCls'>Welcome Back!</h1>
							<p className='containAlignCls'>
								To keep connected with us please login with your personal info
							</p>
						</div>
						<div className='overlay-panel overlay-right'>
							<h1>Hello, Friend!</h1>
							<p>Enter your personal details and start journey with us</p>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer />
		</div >
	);
}




