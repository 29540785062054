import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import Sidebar from '../../AdminPanel/SideBar/Sidebar.tsx';
import Navbar from '../../AdminPanel/Navbar/Navbar.tsx';
import CreteSymptom from './CreateSymptom.js';
import UpdateSymptom from './UpdateSymptom.js';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../TeleHealth.css';
import { useNavigate } from 'react-router-dom';
import QuestionsList from '../Questions/QuestionsList.js';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import NoImage from '../../TeleHealth/Images/NoImage.png';


function SymptomsList() {
	const UAToken = localStorage.getItem("accessToken");
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [showAddMode, setShowAddMode] = useState(false);
	const [showEditMode, setShowEditMode] = useState(false);
	const [showQuesMode, setShowQuesMode] = useState(false);

	const [selectedRowItem, setSelectedRowItem] = useState(null)
	const [selectedUserId, setSelectedUserId] = useState(null);
	const [selectedRowId, setSelectedRowId] = useState(null);
	const [isActive, setIsActive] = useState(false);
	const [getReloadData, setGetReloadData] = useState(false);


	const getSeverity = (status) => {
		switch (status) {
			case 'Active':
				return 'active-status';
			case 'In-Active':
				return 'In-active-status';
		}
	};
	const apiEndPoint = 'https://ciaitelehealth.com/api';

	useEffect(() => {
		if (UAToken && UAToken !== "" && UAToken !== null) {
			navigate('/symptomsList');
		}
		else {
			navigate('/')
		}
	}, [UAToken]);


	useEffect(() => {
		document.body.classList.add('bg-black-dashboard');
		return () => {
			document.body.classList.remove('bg-black-dashboard');
		};
	}, []);

	useEffect(() => {
		if (getReloadData) {
			getSymptomsList();
			setGetReloadData(false);
		}
	}, [getReloadData])


	// const setGetReloadData = (dataType) => {
	// 	if(dataType) {
	// 		console.log("teste ", dataType);
	// 		getSymptomsList();
	// 	}
	// }


	useEffect(() => {
		if (isActive) {
			document.body.classList.add('bg-black-darkMode-dashboard');
			document.body.classList.remove('bg-black-dashboard');
		} else {
			document.body.classList.remove('bg-black-darkMode-dashboard');
			document.body.classList.add('bg-black-dashboard');
		}
	}, [isActive]);

	const handleChange = () => {
		setIsActive(!isActive);
	}

	useEffect(() => {
		const token = localStorage.getItem('accessToken');
		if (!!token) {
			getSymptomsList();
		}
	}, []);

	const getSymptomsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');

			const response = await fetch(`${apiEndPoint}/symptoms/get-symptoms-list`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				}
			});

			console.log('response response: ', response);			

			// if (response.msg === "The token provided has expired or Invalid. Please log in to obtain a new token.") {
			// 	navigate('/');
			// }

			if (!response.ok) {
				// localStorage.setItem("accessToken","");
				// navigate('/');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}

			const actualData = await response.json();
			setData(actualData.data);
			console.log('rrrrrrrrrrrrr', actualData)
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}
	

	const showQueList = () => {
		console.log("onclick list")
		navigate('/questionsList');

	}

	const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil"  title='Edit' rounded outlined className="mr-2 editBtnFocus" onClick={() => {
                    	setSelectedRowItem(rowData)
						setShowEditMode(true)
                }}/>
                <Button icon="pi pi-trash"  title='Delete' rounded outlined className="deleteBtnFocus" onClick={() => deleteConfirm(rowData.id,rowData)}  />
				<Button  icon='pi pi-list'  className="mr-5 editBtnFocus" severity="help" rounded outlined  data-id={showQuesMode}  to='/questionsList'  title='List'
					onClick={() =>{
						//showQueList();
						setShowQuesMode(true)
					}}
				>
				</Button>
            </React.Fragment>
        );
    };

	const statusBodyTemplate = (data) => {
		return <Tag value={data.status} severity={getSeverity(data.status)}></Tag>;
	};

	const imageBodyTemplate = (data) =>{
		return <img  height={80} width={80}
		//src="https://ciaith.s3.ap-south-1.amazonaws.com/specialities/ayurveda.png" 
		src={data.image !==null ? data.image : NoImage}
		alt={"Carlie Anglemire"}/>
   	}


	const deleteConfirm = (userId) => {
		confirmDialog({
			message: 'Are you sure you want to delete this Symptom?',
			header: 'Confirmation',
			accept: () => deleteSymptoms(userId),
		});
	}

	const deleteSymptoms = async (Id) => {
		try {
			const token = localStorage.getItem('accessToken');
			await fetch(`${apiEndPoint}/symptoms/delete-symptom/${Id}`, {
				method: 'DELETE',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			})
				.then(response => response.json())
				.then(actualData => {
					if(actualData.msg === "Symptom deleted successfully"){
						toast.success("Symptom deleted successfully");
					    getSymptomsList();
					}
					
				})
				.catch(err => {
					console.log(err.message);
				});
		}
		catch (e) {
			console.log(e)
		}
	}

	return (
		<>
			<div className='dashboard'>
				<Sidebar isActive={isActive} />
				<div className='dashboardContainer'>
					<Navbar isActive={isActive} handleChange={handleChange} />
					<div className='users-page'>
						<div className='container'>
							<div className='users-list'>
								<div className='addNewUser'>
									<div className='header-users'>
										<h6>Symptoms</h6>
									</div>
									<button className='addUserCls' title='Add User' onClick={() => setShowAddMode(true)}>
										<i className='pi pi-plus' style={{ paddingRight: '6px' }}></i> Create
									</button>
								</div>
								<DataTable value={data} paginator rows={10}>
									<Column field="symptom" header="Symptom" sortable filter></Column>
									<Column field="specialization" header="Specialization" sortable filter></Column>
									<Column field="image" header="Image"  body={imageBodyTemplate} sortable filter></Column>
									<Column header="Status" body={statusBodyTemplate} sortable filter></Column>
									<Column header="Actions" body={actionBodyTemplate}></Column>
								</DataTable>
							</div>
						</div>
						<Dialog header="Add Symptom"
							visible={showAddMode}
							style={{ width: '39vw' }}
							onHide={() => setShowAddMode(false)}>

							<CreteSymptom visible={showAddMode} userId={selectedUserId} selectedRowItem={selectedRowItem} setGetReloadData={setGetReloadData} setUserAdded={() => {
								setShowAddMode(false);
							}} />
						</Dialog>

						<Dialog header="Edit Symptom"
							visible={showEditMode}
							style={{ width: '39vw' }}
							onHide={() => setShowEditMode(false)}>

							<UpdateSymptom visible={showEditMode} userId={selectedUserId} updateData={selectedRowItem} updateDateId={selectedRowId} setGetReloadData={setGetReloadData} setUserEdited={() => {
								setShowEditMode(false);
							}} />
						</Dialog> 
							{showQuesMode && <QuestionsList visible={showQuesMode} updateData={selectedRowItem}/>}
						<ConfirmDialog />
						<ToastContainer />
					</div>
				</div>
			</div>

		</>
	)
}

export default SymptomsList;