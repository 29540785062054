import React, { useState, useEffect } from 'react';
import '../../AdminPanel/style.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";

function ReviewNotes(props) {

	const { doctorID, setGetReloadData } = props;
	
	const [rejectedReason, setRejectedReason] = useState("");
	const [errorNameMessage, setErrorNameMessage] = useState("");

	const [initialValues, setInitialValues] = useState({
		doctorId: doctorID ? doctorID : '',
		rejectedReason: rejectedReason ? rejectedReason : ''
	});

	const navigate = useNavigate();

	const apiEndPoint = 'https://ciaitelehealth.com/api';

	useEffect(() => {
		const token = localStorage.getItem('accessToken');
		if (!!token) {
			
		}
	}, []);

	const cancelDoctorData = (e) => {
		//setReviewNotesFlag(false);
		navigate('/pendingDoctorsList');
	};
	
	const [allErrors, setAllErrors] = useState({
		nameErr: "",
	});

	const validationForm = (type, value) => {

		let errorMessage = "";
		if (type === "name") {
			if (value === "") {
				errorMessage = "Enter the Rejected Reason";
			}
			
			setErrorNameMessage(errorMessage)
			setAllErrors({ ...allErrors, ['nameErr']: errorMessage });
		}
	}
	
	const rejectedReasonHandle = async () => { 
		if (rejectedReason === '' ) {
			validationForm('name', initialValues.rejectedReason);
		} else {
			const url = `${apiEndPoint}/doctors/reject-doctor`; 
			
			const formData = {
				doctorId: doctorID,
				rejectedReason: rejectedReason
			};

			const token = localStorage.getItem('accessToken');
			const options = {
				method: 'PUT',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			};

			await fetch(url, options)
				.then(response => response.json())
				.then(data => {

					console.log('data: ', data);
					if (data.msg === 'Doctor Rejected Successfully') {
						setInitialValues({ ...initialValues, rejectedReason: '' });
						setTimeout(() => {
							navigate('/pendingDoctorsList');
						}, 2000)
						toast.success("Doctor Rejected Successfully.");
						props.setUserEdited();
						setGetReloadData(true);
					} else {						
						toast.error("Unable to reject the doctor. This doctor already approved/rejected");
						setTimeout(() => {
							navigate('/pendingDoctorsList');
						}, 2000)
						
					}
				})
				.catch(error => console.error('Error:', error));
		}
	}

	const handleInputChange = (e) => { 
		const { name, value, type, checked } = e.target;
		setRejectedReason(value)
		setInitialValues(prevState => ({
			...prevState,
			[name]: type === 'checkbox' ? checked : value
		}));
	};


	//UpdateDistrictData

	
	return (
		<>
			<div>
				<form className="form-container-doctor" >
					
					<div className="form-group">
						<label htmlFor="bio">Reject Remarks:</label>
						<textarea
							id="rejectedReason"
							name="rejectedReason"
							value={initialValues.rejectedReason}
							onChange={handleInputChange}
							placeholder="Reject Remarks"
						/>
						<div className="error">{errorNameMessage}</div>
					</div>


					<div className="button-container">
						<button className='button-savecls' type="button" onClick={rejectedReasonHandle}>Submit</button>&nbsp;&nbsp;
						<button onClick={cancelDoctorData} className='button-cancle-cls' type="reset">Cancel</button>
					</div>
					<ToastContainer />
				</form>
			</div>
		</>
	);
}

export default ReviewNotes;