import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import '../TeleHealth.css';
import Sidebar from '../../AdminPanel/SideBar/Sidebar.tsx';
import Navbar from '../../AdminPanel/Navbar/Navbar.tsx';
import CreateQualification from './CreateQualification.js';
import UpdateQualification from './UpdateQualification.js';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';


function QualificationsList() {
	const  UAToken =  localStorage.getItem("accessToken");
	const navigate = useNavigate();

	const [data, setData] = useState([]);
	const [showAddMode, setShowAddMode] = useState(false);
	const [showEditMode, setShowEditMode] = useState(false);
	const [selectedRowItem, setSelectedRowItem] = useState(null)
	const [isActive, setIsActive] = useState(false);
	const [getReloadData,setGetReloadData]= useState(false);


	const getSeverity = (status) => {
		switch (status) {
			case 'Active':
				return 'active-status';
			case 'In-Active':
				return 'In-active-status';
		}
	};

	const apiEndPoint = 'https://ciaitelehealth.com/api';

	useEffect(() => {
		document.body.classList.add('bg-black-dashboard');
		return () => {
			document.body.classList.remove('bg-black-dashboard');
		};
	}, []);

	useEffect(() => {
		if(UAToken && UAToken!=="" && UAToken!==null)  {
			navigate('/qualificationsList');
		}  
		else{
			navigate('/')
		}
	}, [UAToken]);

	useEffect(() => {
		if (getReloadData) {
			getQualificationsList();
			setGetReloadData(false);
		}
	}, [getReloadData])

	useEffect(() => {
		if (isActive) {
		  document.body.classList.add('bg-black-darkMode-dashboard');
		  document.body.classList.remove('bg-black-dashboard');
		} else {
			document.body.classList.remove('bg-black-darkMode-dashboard');
			document.body.classList.add('bg-black-dashboard');
		}
	  }, [isActive]);

	const handleChange = () => {
		setIsActive(!isActive);
	}

	useEffect(() => {
		const token = localStorage.getItem('accessToken');
		if(!!token){
		getQualificationsList();}
	}, []);

	const getQualificationsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');
	
			const response = await fetch(`${apiEndPoint}/qualifications/get-qualifications-list`, {
				method: 'GET', 
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});
	
			if (!response.ok) {
				// localStorage.setItem("accessToken","");
				// navigate('/login');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setData(actualData.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}

	const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button  icon="pi pi-pencil" title='Edit' rounded outlined className="mr-2 editBtnFocus" onClick={() => {
                    setSelectedRowItem(rowData)
                    setShowEditMode(true)
                }}/>
                <Button icon="pi pi-trash" title='Delete'  rounded outlined className="deleteBtnFocus" onClick={() => deleteUserConfirm(rowData.id)} />
            </React.Fragment>
        );
    };

	const statusBodyTemplate = (data) => {
		return <Tag value={data.status} severity={getSeverity(data.status)}></Tag>;
	};



	const deleteUserConfirm = (userId) => {
		confirmDialog({
			message: 'Are you sure you want to delete this Qualification?',
			header: 'Confirmation',
			accept: () => deleteQualificationData(userId),
		});
	}

	const deleteQualificationData = async (Id) => {
		try {
			const token = localStorage.getItem('accessToken');
			await fetch(`${apiEndPoint}/qualifications/delete-qualification/${Id}`, {
				method: 'DELETE',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			  })
			  .then(response => response.json())
			  .then(actualData => {
				console.log('actualData ', actualData);
				toast.success("Qualification Delete Successfully.");
				getQualificationsList();
			  })
			  .catch(err => {
				console.log(err.message);
			  });
		}
		catch (e) {
			console.log(e)
		}
	}


	return (
		<>
			<div className='dashboard'>
				<Sidebar isActive={isActive} />
				<div className='dashboardContainer'>
					<Navbar isActive={isActive} handleChange={handleChange} />
					<div className='users-page'>
						<div className='container'>
							<div className='users-list'>
								<div className='addNewUser'>
									<div className='header-users'>
										<h6>Qualifications</h6>
									</div>
									<button className='addUserCls' title='Add User' onClick={() => setShowAddMode(true)}>
										<i className='pi pi-plus' style={{paddingRight:'6px'}}></i> Create
									</button>
								</div>
								<DataTable value={data} paginator rows={10}>
									<Column style={{ width: '60%' }} field="qualification" header="Qualification" sortable filter></Column>
									<Column style={{ width: '15%' }} header="Status" body={statusBodyTemplate} sortable filter></Column>
									<Column style={{ width: '15%' }} header="Actions" body={actionBodyTemplate}></Column>
								</DataTable>
							</div>
						</div>
						<Dialog header="Add Qualification"
							visible={showAddMode}
							style={{ width: '39vw' }}
							onHide={() => setShowAddMode(false)}>

							<CreateQualification setGetReloadData={setGetReloadData} setUserAdded={() => {
								setShowAddMode(false);
							}} />
						</Dialog>

						<Dialog header="Edit Qualification"
							visible={showEditMode}
							style={{ width: '39vw' }}
							onHide={() => setShowEditMode(false)}>

							<UpdateQualification visible={showEditMode} setGetReloadData={setGetReloadData} updateData={selectedRowItem} setUserEdited={() => {
								setShowEditMode(false);
							}} />
						</Dialog>

						<ConfirmDialog />

					</div>
				</div>
			</div>

		</>
	)
}

export default QualificationsList;