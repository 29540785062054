import React, { useState, useEffect } from 'react';
import '../../TeleHealth/TeleHealth.css';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../AdminPanel/Navbar/Navbar.tsx';
import Sidebar from '../../AdminPanel/SideBar/Sidebar.tsx';
import { ToastContainer, toast } from "react-toastify";
import { id } from 'date-fns/locale';

function CreateDoctor(props) {
	const { setGetReloadData } = props;
	const UAToken = localStorage.getItem("accessToken");
	const [isActive, setIsActive] = useState(false);
	const [specializationList, setSpecializationList] = useState([]);
	const [QualificationsList, setQualificationList] = useState([]);
	const [clinicsList, setClinicsList] = useState([]);

	const [selectedOption, setSelectedOption] = useState("");
	const [selectedItems, setSelectedItems] = useState([]);
	const [selectOnlineItems, setSelectOnlineItems] = useState([]);
	const [errorOnlinecheckbox, setErrorOnlineCheckbox] = useState("");
	const [errorOfflinecheckbox, setErrorOfflineCheckbox] = useState("");
	const [errorNameMessage, setErrorNameMessage] = useState("");
	const [errorBioMessage, setErrorBioMessage] = useState("");
	const [errorMsgPhoneNumber, setErrorMsgPhoneNumber] = useState("");
	const [emailErrMsg, setEmailErrMsg] = useState("");
	const [errorConsultationFeeMessage, setErrorConsultationFeeMessage] = useState("");
	const [errorgender, setErrorGender] = useState("");
	const [errorAgeMessage, setErrorAgeMessage] = useState("");
	const [errorSpecializationsMessage, setErrorSpecializationsMessage] = useState("");
	const [errorExperienceMessage, setErrorExperienceMessage] = useState("");
	const [errorClinicsMessage, setErrorClinicsMessage] = useState("");
	const [errorIdMessage, setErrorIdMessage] = useState("");
	const [errorCollegeMessage, setErrorCollegeMessage] = useState("");
	const [errorLocationMessage, setErrorLocationMessage] = useState("");
	const [errorFromYearMessage, setErrorFromYearMessage] = useState("");
	const [errorToYearMessage, setErrorToYearMessage] = useState("");

	const navigate = useNavigate();
	const apiEndPoint = 'https://ciaitelehealth.com/api';

	const [initialValues, setInitialValues] = useState({
		name: '',
		bio: '',
		phoneNumber: '',
		email: '',
		isOfflineConsultationAvailable: false,
		isOnlineConsultationAvailable: false,
		consultationFee: '',
		gender: '',
		age: '',
		specializations: [{ id: '', experience: '' }],
		qualifications: [{ id: '', college: '', location: '', fromYear: '', toYear: '' }],
		clinics: [{ id: '' }],
	});

	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target;
		setInitialValues(prevState => ({
			...prevState,
			[name]: type === 'checkbox' ? checked : value
		}));
		validationForm(name, value)
	};

	const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
		validationForm(event.target.name, event.target.value)
		setErrorGender("");
	};

	const handleCheckboxChange = (event) => {
		const { value, checked, name } = event.target;
		if (checked) {
			setSelectedItems([...selectedItems, value]);
		}
		else {
			setSelectedItems(selectedItems.filter((item) => item !== value));
		}
		validationForm(name, value)
		setErrorOfflineCheckbox("");
	};

	const handleOnlineCheckboxChange = (event) => {
		const { value, checked, name } = event.target;
		if (checked) {
			setSelectOnlineItems([...selectOnlineItems, value]);
		}
		else {
			setSelectOnlineItems(selectOnlineItems.filter((item) => item !== value));
		}
		validationForm(name, value)
		setErrorOnlineCheckbox("");
	};

	useEffect(() => {
		if (UAToken && UAToken !== "" && UAToken !== null) {
			navigate('/createdoctor');
		}
		else {
			navigate('/')
		}
	}, [UAToken]);

	const handleArrayInputChange = (e, arrayName, index) => {
		console.log('index', index)
		const { name, value } = e.target;
		setInitialValues(prevState => ({
			...prevState,
			[arrayName]: [
				...prevState[arrayName].slice(0, index),
				{
					...prevState[arrayName][index],
					[name.split('.')[1]]: value
				},
				...prevState[arrayName].slice(index + 1)
			]

		}));
		validationForm(name, value)
	};

	const handleChange = () => {
		setIsActive(!isActive);
	}

	useEffect(() => {
		getSpecializations();
		getQualificationsList();
		getClinicsList();
	}, [])

	const getSpecializations = async () => {
		try {
			const token = localStorage.getItem('accessToken');

			const response = await fetch(`${apiEndPoint}/specializations/get-specializations-list`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				//localStorage.setItem("accessToken","");
				//navigate('/login');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setSpecializationList(actualData.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const getQualificationsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');

			const response = await fetch(`${apiEndPoint}/qualifications/get-qualifications-list`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				//localStorage.setItem("accessToken","");
				//navigate('/login');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setQualificationList(actualData.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}

	const getClinicsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');

			const response = await fetch(`${apiEndPoint}/clinics/get-clinics-list?search=&page=${1}&pageSize=${10}`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				// localStorage.setItem("accessToken","");
				// navigate('/login');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			setClinicsList(actualData.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	function validateNum(value) {
		var patForReqdFld = /^(\-)?([\d]+(?:\.\d{1,2})?)$/;

		if (value === "") {
			return 'Empty';
		}
		else if (!patForReqdFld.test(value)) {
			return true;
		}
		else if (value.length !== 5) {
			return 'NotValid';
		}
		return;
	}

	function validageNum(value) {
		var patForReqdFld = /^(\-)?([\d]+(?:\.\d{1,2})?)$/;

		if (value === "") {
			return 'Empty';
		}
		else if (!patForReqdFld.test(value)) {
			return true;
		}
		else if (value.length !== 2) {
			return 'NotValid';
		}
		return;
	}

	function validexperienceNum(value) {
		var patForReqdFld = /^(\-)?([\d]+(?:\.\d{1,2})?)$/;

		if (value === "") {
			return 'Empty';
		}
		else if (!patForReqdFld.test(value)) {
			return true;
		}
		else if (value.length !== 2) {
			return 'NotValid';
		}
		return;
	}

	function ValiadateMobileNum(value) {
		var phoneVal = /^[0-9]{10}$/g;
		if (!phoneVal.test(value)) {
			return true;
		}
	}

	function verifyEmailValidation(value) {
		var emailVal = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

		if (value === '') {
			return 'isEmailEmpty';
		}
		else if (!emailVal.test(value)) {
			return true;
		}
		return;
	}

	const validationForm = (type, value) => {
		console.log('value123', type, value)
		let errorMessage = "";
		if (type === "name") {
			if (value === "") {
				errorMessage = "Enter Name ";
			}
			else if (value.length < 3) {
				errorMessage = "Enter Atleast 3 Characters";
			}

			else if (value.length > 20) {
				errorMessage = "Enter less Than 20 Characters only";
			}
			setErrorNameMessage(errorMessage)
			setAllErrors({ ...allErrors, ['nameErr']: errorMessage });
		}
		if (type === "bio") {
			if (value === "") {
				errorMessage = "Enter Bio ";
			}
			else if (value.length < 10) {
				errorMessage = "Enter Atleast 10 Characters";
			}

			else if (value.length > 20) {
				errorMessage = "Enter less than 20 Characters Only";
			}
			setErrorBioMessage(errorMessage)
			setAllErrors({ ...allErrors, ['bioErr']: errorMessage });
		}
		if (type === 'phoneNumber') {
			if (value === '') {
				errorMessage = "Enter Phone Number";
			}
			else if (ValiadateMobileNum(value) === true) {
				errorMessage = "Enter Valid Phone Number";
			}
			setErrorMsgPhoneNumber(errorMessage);
			setAllErrors({ ...allErrors, ['phoneNumberErr']: errorMessage });
		}
		if (type === "email") {
			if (verifyEmailValidation(value) === 'isEmailEmpty') {
				errorMessage = "Enter Email";
			}
			else if (verifyEmailValidation(value) === true) {
				errorMessage = "Enter Valid Email";
			}
			setEmailErrMsg(errorMessage);
			setAllErrors({ ...allErrors, ['emailErr']: errorMessage });
		}
		if (type === "consultationFee") {
			if (validateNum(value) === "Empty") {
				errorMessage = "Enter Consultation Fee";
			}
			else if (validateNum(value) === 'NotValid') {
				errorMessage = "Consultation Fee length shuld be  Only 5 Characters";
			} else if (validateNum(value) === true) {
				errorMessage = "Only Numberic Chracters are allowed";
			}
			setErrorConsultationFeeMessage(errorMessage);
			setAllErrors({ ...allErrors, ['consultationFeeErr']: errorMessage });
		}
		if (type === 'gender') {
			if (selectedOption === '') {
				errorMessage = 'Select Gender';
			}
			setErrorGender(errorMessage);
			setAllErrors({ ...allErrors, ['selectedOptionErr']: errorMessage })
		}
		if (type === "age") {
			if (validageNum(value) === "Empty") {
				errorMessage = "Enter Age";
			}
			else if (validageNum(value) === 'NotValid') {
				errorMessage = "Age length shuld be  Only 2 Characters";
			} else if (validageNum(value) === true) {
				errorMessage = "Only Numberic Chracters are allowed";
			}
			setErrorAgeMessage(errorMessage);
			setAllErrors({ ...allErrors, ['ageErr']: errorMessage });
		}
		if (type === 'checkbox') {
			if (selectedItems.length === 0) {
				errorMessage = "Click Offline";
			}
			setErrorOfflineCheckbox(errorMessage)
			setAllErrors({ ...allErrors, ['selectedItemsErr']: errorMessage })
		}
		if (type === 'checkbox') {
			if (selectOnlineItems.length === 0) {
				errorMessage = "Click Online ";
			}
			setErrorOnlineCheckbox(errorMessage)
			setAllErrors({ ...allErrors, ['selectOnlineItemsErr']: errorMessage })
		}
		if (type === "specializations[0].id") {
			if (value === '') {
				errorMessage = 'Select Specializations';
			}
			setErrorSpecializationsMessage(errorMessage)
			setAllErrors({ ...allErrors, ['specializationsErr']: errorMessage })
		}
		if (type === "specializations[0].experience") {
			if (validexperienceNum(value) === "Empty") {
				errorMessage = "Enter Experience";
			}
			else if (validexperienceNum(value) === 'NotValid') {
				errorMessage = "Experience length shuld be  Only 2 Characters";
			} else if (validexperienceNum(value) === true) {
				errorMessage = "Only Numberic Chracters are allowed";
			}
			setErrorExperienceMessage(errorMessage);
			setAllErrors({ ...allErrors, ['experienceErr']: errorMessage });
		}
		if (type === "clinics[0].id") {
			if (value === '') {
				errorMessage = 'Select Clinics';
			}
			setErrorClinicsMessage(errorMessage)
			setAllErrors({ ...allErrors, ['clinicsErr']: errorMessage })
		}
		if (type === "qualifications[0].id") {
			if (value === '') {
				errorMessage = 'Select Id';
			}
			setErrorIdMessage(errorMessage)
			setAllErrors({ ...allErrors, ['idErr']: errorMessage })
		}
		if (type === "qualifications[0].college") {
			if (value === '') {
				errorMessage = 'Enter College';
			}
			setErrorCollegeMessage(errorMessage)
			setAllErrors({ ...allErrors, ['collegeErr']: errorMessage })
		}
		if (type === "qualifications[0].location") {
			if (value === '') {
				errorMessage = 'Enter Location';
			}
			setErrorLocationMessage(errorMessage)
			setAllErrors({ ...allErrors, ['locationErr']: errorMessage })
		}
		if (type === "qualifications[0].fromYear") {
			if (value === '') {
				errorMessage = 'Enter FromYear';
			}
			setErrorFromYearMessage(errorMessage)
			setAllErrors({ ...allErrors, ['fromYearErr']: errorMessage })
		}
		if (type === "qualifications[0].toYear") {
			if (value === '') {
				errorMessage = 'Enter ToYear';
			}
			setErrorToYearMessage(errorMessage)
			setAllErrors({ ...allErrors, ['toYearErr']: errorMessage })
		}
	}

	const [allErrors, setAllErrors] = useState({
		nameErr: "",
		bioErr: "",
		phoneNumberErr: "",
		emailErr: "",
		consultationFeeErr: "",
		selectedOptionErr: "",
		ageErr: "",
		selectedItemsErr: "",
		selectOnlineItemsErr: "",
		specializationsErr: "",
		experienceErr: "",
		clinicsErr: "",
		idErr: "",
		collegeErr: "",
		locationErr: "",
		fromYearErr: "",
		toYearErr: "",
	});
	const CreateDoctorData = async (e) => {
		e.preventDefault();
		if (initialValues.name === "" || initialValues.bio === "" || initialValues.phoneNumber === "" || initialValues.email === "" || initialValues.consultationFee === "" ||
			selectedItems.length < 0 || selectOnlineItems.length < 0 || selectedOption === '' ||
			initialValues.age === "" || initialValues.specializations[0].id, initialValues.specializations[0].experience === "" ||
			initialValues.qualifications[0].id,
			initialValues.qualifications[0].college,
			initialValues.qualifications[0].location, initialValues.qualifications[0].fromYear,
			initialValues.qualifications[0].toYear === "" || initialValues.clinics[0].id === ""
		) {
			validationForm('name', initialValues.name);
			validationForm('bio', initialValues.bio);
			validationForm('phoneNumber', initialValues.phoneNumber);
			validationForm('email', initialValues.email);
			validationForm('consultationFee', initialValues.consultationFee);
			validationForm('gender', selectedOption);
			validationForm('age', initialValues.age);
			validationForm('checkbox', selectedItems)
			validationForm('checkbox', selectOnlineItems)
			validationForm('specializations[0].id', initialValues.specializations[0].id);
			validationForm('specializations[0].experience', initialValues.specializations[0].experience);
			validationForm('clinics[0].id', initialValues.clinics[0].id);
			validationForm('qualifications[0].id', initialValues.qualifications[0].id);
			validationForm('qualifications[0].college', initialValues.qualifications[0].college);
			validationForm('qualifications[0].location', initialValues.qualifications[0].location);
			validationForm('qualifications[0].fromYear', initialValues.qualifications[0].fromYear);
			validationForm('qualifications[0].toYear', initialValues.qualifications[0].toYear);
		}
		else {
			const url = `${apiEndPoint}/doctors/create-doctor`;
			const formData = {
				name: initialValues.name,
				bio: initialValues.bio,
				phoneNumber: initialValues.phoneNumber,
				email: initialValues.email,
				consultationFee: initialValues.consultationFee,
				isOfflineConsultationAvailable: selectedItems,
				isOnlineConsultationAvailable: selectOnlineItems,
				// isOfflineConsultationAvailable: initialValues.isOfflineConsultationAvailable,
				// isOnlineConsultationAvailable: initialValues.isOnlineConsultationAvailable,
				gender: selectedOption,
				age: initialValues.age,
				specializations: [{ id: initialValues.specializations[0].id, experience: initialValues.specializations[0].experience }],
				qualifications: [{
					id: initialValues.qualifications[0].id,
					college: initialValues.qualifications[0].college,
					location: initialValues.qualifications[0].location,
					fromYear: initialValues.qualifications[0].fromYear,
					toYear: initialValues.qualifications[0].toYear
				}],
				clinics: [initialValues.clinics[0].id]
			};

			console.log('form', formData)
			const token = localStorage.getItem('accessToken');

			const options = {
				method: 'POST',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			};
			await fetch(url, options)
				.then(response => response.json())
				.then(data => {
					if (data.msg === 'success') {
						console.log('data', data)
						setInitialValues({
							...initialValues,
							name: '',
							bio: '',
							phoneNumber: '',
							email: '',
							isOfflineConsultationAvailable: false,
							isOnlineConsultationAvailable: false,
							consultationFee: '',
							gender: '',
							age: '',
							specializations: [{ id: '', experience: '' }],
							qualifications: [{ id: '', college: '', location: '', fromYear: '', toYear: '' }],
							clinics: []
						});
						setTimeout(() => {
							navigate('/doctorsList');
						}, 2000)
						toast.success("Create Doctor Successfully.");
						props.setUserAdded();
						setGetReloadData(true);
					}
				})

				.catch(error => console.error('Error:', error));
		}

	}

	const handleReset = () => {
		setInitialValues({
			...initialValues,
			name: '',
			bio: '',
			phoneNumber: '',
			email: '',
			isOfflineConsultationAvailable: false,
			isOnlineConsultationAvailable: false,
			consultationFee: '',
			gender: '',
			age: '',
			specializations: [{ id: '', experience: '' }],
			qualifications: [{ id: '', college: '', location: '', fromYear: '', toYear: '' }],
			clinics: [{ id: '' }]
		});
		setAllErrors({
			nameErr: "",
			bioErr: "",
			phoneNumberErr: "",
			emailErr: "",
			consultationFeeErr: "",
			selectedOptionErr: "",
			ageErr: "",
			selectedItemsErr: "",
			selectOnlineItemsErr: "",
			specializationsErr: "",
			experienceErr: "",
			clinicsErr: "",
			idErr: "",
			collegeErr: "",
			locationErr: "",
			fromYearErr: "",
			toYearErr: ""
		});
		setErrorNameMessage("");
		setErrorBioMessage("");
		setErrorMsgPhoneNumber("");
		setEmailErrMsg("");
		setErrorConsultationFeeMessage("");
		setErrorGender("");
		setSelectedOption("");
		setErrorAgeMessage("");
		setErrorOfflineCheckbox("");
		setSelectedItems("");
		setErrorOnlineCheckbox("");
		setSelectOnlineItems("");
		setErrorSpecializationsMessage("");
		setErrorExperienceMessage("");
		setErrorClinicsMessage("");
		setErrorIdMessage("");
		setErrorCollegeMessage("");
		setErrorLocationMessage("");
		setErrorFromYearMessage("");
		setErrorToYearMessage("");
	}

	return (
		<div className='dashboard'>
			<Sidebar isActive={isActive} />
			<div className='dashboardContainer'>
				<Navbar isActive={isActive} handleChange={handleChange} />
				<form className="form-container-doctor" >
					<h5>Create Doctor</h5>
					<div className="form-group">
						<label htmlFor="name">Name:</label>
						<input
							type="text"
							id="name"
							name="name"
							value={initialValues.name}
							onChange={handleInputChange}
							placeholder="Enter Name"
						/>
						<div className="error">{errorNameMessage}</div>
					</div>

					<div className="form-group">
						<label htmlFor="bio">Bio:</label>
						<textarea
							id="bio"
							name="bio"
							value={initialValues.bio}
							onChange={handleInputChange}
							placeholder="Enter Bio"
						/>
						<div className="error">{errorBioMessage}</div>
					</div>

					<div className="form-group">
						<label htmlFor="phoneNumber">Phone Number:</label>
						<input
							type="tel"
							id="phoneNumber"
							name="phoneNumber"
							value={initialValues.phoneNumber}
							onChange={handleInputChange}
							placeholder="Enter Phone Number"
						/>
						<div className="error"> {errorMsgPhoneNumber} </div>
					</div>

					<div className="form-group">
						<label htmlFor="email">Email:</label>
						<input
							type="email"
							id="email"
							name="email"
							value={initialValues.email}
							onChange={handleInputChange}
							placeholder="Enter Email"
						/>
						<div className="error"> {emailErrMsg} </div>
					</div>

					<div className="form-group">
						<label htmlFor="consultationFee">Consultation Fee:</label>
						<input
							type="text"
							id="consultationFee"
							name="consultationFee"
							value={initialValues.consultationFee}
							onChange={handleInputChange}
							placeholder="Enter Consultation Fee"
						/>
						<div className="error"> {errorConsultationFeeMessage} </div>
					</div>

					<div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>
						<label htmlFor="isOfflineConsultationAvailable">Offline Consultation Available</label>
						<input
							type="checkbox"
							id="isOfflineConsultationAvailable"
							name="isOfflineConsultationAvailable"
							value='true'
							className='checkboxAlignCls'
							checked={selectedItems.includes('true')}
							// checked={initialValues.isOfflineConsultationAvailable}
							onChange={handleCheckboxChange}
						/>
					</div>
					<div className='errorOffline'>{errorOfflinecheckbox}</div>

					<div className="form-group" style={{ display: 'flex', alignItems: 'center' }}>
						<label htmlFor="isOnlineConsultationAvailable">Online Consultation Available</label>
						<input
							type="checkbox"
							id="isOnlineConsultationAvailable"
							name="isOnlineConsultationAvailable"
							value='true'
							className='checkboxAlignCls'
							checked={selectOnlineItems.includes('true')}
							// checked={initialValues.isOnlineConsultationAvailable}
							onChange={handleOnlineCheckboxChange}
							style={{ marginRight: '5px' }}
						/>
					</div>
					<div className='errorOnline'>{errorOnlinecheckbox}</div>

					<div className="form-group radioContainer" style={{ display: 'flex', alignItems: 'center' }}>
						<label htmlFor="gender" style={{ marginBottom: "1px" }}>Gender:</label>
						<input
							type="radio"
							name="gender"
							value="male"
							checked={selectedOption === "male"}
							onChange={handleOptionChange}
						/>
						Male &nbsp;
						<input
							type="radio"
							name="gender"
							value="female"
							checked={selectedOption === "female"}
							onChange={handleOptionChange}
						/>
						Female &nbsp;
						<input
							type="radio"
							name="gender"
							value="other"
							checked={selectedOption === "other"}
							onChange={handleOptionChange}
						/>
						Other
					</div>
					<div className='errorgender'>{errorgender}</div>

					<div className="form-group">
						<label htmlFor="age">Age:</label>
						<input
							type="text"
							id="age"
							name="age"
							value={initialValues.age}
							onChange={handleInputChange}
							placeholder="Enter Age"
						/>
						<div className="error"> {errorAgeMessage} </div>
					</div>

					<div className="form-group">
						<p>
							<label>Specialization:</label>
							{initialValues.specializations.map((specialization, index) => (
								<div key={index} className="array-input">
									<select
										className="doctorsListCls form-control"
										style={{ display: 'flex' }}
										name={`specializations[${index}].id`}
										id='specialization.id'
										value={specialization.id}
										onChange={(e) => handleArrayInputChange(e, 'specializations', index)}
									>
										<option value="">Select</option>
										{specializationList.map((option, index) => (
											<option
												key={index}
												value={option.id}
											>
												{option.specialization}
											</option>
										))}
									</select>

									<input
										type="text"
										name={`specializations[${index}].experience`}
										style={{
											marginLeft: '10px',
											paddingRight: '10px'
										}}
										id='specialization.experience'
										value={specialization.experience}
										onChange={(e) => handleArrayInputChange(e, 'specializations', index)}
										placeholder="Experience"
									/>
								</div>
							))}
							<div className="errorSpecializations">{errorSpecializationsMessage}</div>
							<div className="errorExperience">{errorExperienceMessage}</div>
						</p>
					</div>

					<div className="form-group">
						<p>
							<label>Qualification:</label>
							{initialValues.qualifications.map((qualification, index) => (
								<div key={index} className="array-input">

									<select
										className="doctorsListCls form-control"
										style={{ display: 'flex', marginRight: '10px' }}
										name={`qualifications[${index}].id`}
										value={qualification.id}
										onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
									>
										<option value="">Select</option>
										{QualificationsList.map((option, index) => (
											<option
												key={index}
												value={option.id}
											>
												{option.qualification}
											</option>
										))}
									</select>

									<input
										type="text"
										name={`qualifications[${index}].college`}
										value={qualification.college}
										onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
										placeholder="College"
									/>

									<input
										type="text"
										name={`qualifications[${index}].location`}
										value={qualification.location}
										onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
										placeholder="Location"
									/>

									<input
										type="text"
										name={`qualifications[${index}].fromYear`}
										value={qualification.fromYear}
										onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
										placeholder="FromYear"
									/>

									<input
										type="text"
										name={`qualifications[${index}].toYear`}
										value={qualification.toYear}
										onChange={(e) => handleArrayInputChange(e, 'qualifications', index)}
										placeholder="ToYear"
									/>
								</div>
							))}
							<div className='errorId'>{errorIdMessage}</div>
							<div className='errorCollege'>{errorCollegeMessage}</div>
							<div className='errorLocation'>{errorLocationMessage}</div>
							<div className='errorFromYear'>{errorFromYearMessage}</div>
							<div className='errorToYear'>{errorToYearMessage}</div>
						</p>
					</div>

					<div className="form-group">
						<p>
							<label>Clinic:</label>
							{initialValues.clinics.map((clinic, index) => (
								<div key={index} className="array-input">

									<select
										className="doctorsListCls form-control"
										style={{ display: 'flex', marginRight: '10px' }}
										name={`clinics[${index}].id`}
										value={clinic.id}
										onChange={(e) => handleArrayInputChange(e, 'clinics', index)}
									>
										<option value="">Select</option>
										{clinicsList.map((option, optionindex) => (
											<option
												key={optionindex}
												value={option.id}
											>
												{option.name}
											</option>
										))}
									</select>
								</div>
							))}
							<div className='error'>{errorClinicsMessage}</div>
						</p>
					</div>

					<div className="button-container">
						<button type='button' className='button-savecls' style={{ float: 'right' }} onClick={CreateDoctorData}>Save</button>
						<button type='button' className='button-cancle-cls' style={{ float: 'right', marginRight: '15px' }} onClick={() => handleReset()}>Clear</button>
					</div>
					<ToastContainer />
				</form>
			</div>
		</div>
	);
};

export default CreateDoctor;