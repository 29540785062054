import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Logout(){
    const  UAToken =  localStorage.getItem("accessToken");
	const navigate = useNavigate();
    const [clearUser, setClearUser] = useState(false);

	useEffect(() => {	
        console.log("login", localStorage.getItem("accessToken"));
	
		if(UAToken && UAToken!=="" && UAToken!==null)  {
            localStorage.setItem("messageStatus", "");
		    localStorage.setItem("userRole", "");
            localStorage.setItem("accessToken", "");
            localStorage.clear();
            console.log("inner");

            
            
            setClearUser(true);
			//return navigate('/');
		}  
	}, [UAToken]);

    useEffect(() => {	
        console.log("login", localStorage.getItem("accessToken"));
	
		if(clearUser)  {
            localStorage.clear();
            console.log("inner");
			navigate('/');
            //window.location.href('/');
            console.log("Logout");
		}  
	}, [clearUser]);



      return(
        <div>
             <h2></h2>
        </div>
      )
}