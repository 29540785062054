import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { confirmDialog } from 'primereact/confirmdialog';
import '../TeleHealth.css';
import Sidebar from '../../AdminPanel/SideBar/Sidebar.tsx';
import Navbar from '../../AdminPanel/Navbar/Navbar.tsx';
// import CreateQualification from './CreateQualification.js';
// import UpdateQualification from './UpdateQualification.js';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import CreateQuestion from './CreateQuestion.js';
import UpdateQuestion from './UpdateQuestion.js';

function QuestionsList() {
	const UAToken = localStorage.getItem("accessToken");
	const navigate = useNavigate();

	const [data, setData] = useState([]);
	const [showAddMode, setShowAddMode] = useState(false);
	const [showEditMode, setShowEditMode] = useState(false);
	const [selectedRowItem, setSelectedRowItem] = useState(null)
	const [isActive, setIsActive] = useState(false);
    const [quesData, setQuesData] = useState([]);
	const [getReloadData,setGetReloadData]= useState(false);

	const apiEndPoint = 'https://ciaitelehealth.com/api';

	const [selectedCities, setSelectedCities] = useState(null);

	const getSeverity = (status) => {
		switch (status) {
			case 'Active':
				return 'active-status';
			case 'In-Active':
				return 'In-active-status';
		}
	};

	useEffect(() => {
		document.body.classList.add('bg-black-dashboard');
		return () => {
			document.body.classList.remove('bg-black-dashboard');
		};
	}, []);

	useEffect(() => {
		if (UAToken && UAToken !== "" && UAToken !== null) {
			navigate('/questionsList');
		}
		else {
			navigate('/')
		}
	}, [UAToken]);

	useEffect(() => {
		if (getReloadData) {
			getQuestionsList();
			setGetReloadData(false);
		}
	}, [getReloadData])


	useEffect(() => {
		if (isActive) {
			document.body.classList.add('bg-black-darkMode-dashboard');
			document.body.classList.remove('bg-black-dashboard');
		} else {
			document.body.classList.remove('bg-black-darkMode-dashboard');
			document.body.classList.add('bg-black-dashboard');
		}
	}, [isActive]);

	const handleChange = () => {
		setIsActive(!isActive);
	}

	useEffect(() => {
		getQuestionsList();
	}, []);

	const getQuestionsList = async () => {
		try {
			const token = localStorage.getItem('accessToken');
			const response = await fetch(`${apiEndPoint}/symptoms/get-questions-list`, {
				method: 'GET',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			});

			if (!response.ok) {
				// localStorage.setItem("accessToken","");
				// navigate('/login');
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const actualData = await response.json();
			console.log('actual', actualData)
			//setData(actualData.data);
			let mainArr = [];
			const questionsData = actualData && actualData.data.map((item, index) => {
				let optionData = [];
				
                if (item.option1 !== "") { optionData.push({ id: index, name: item.option1 }) }
                if (item.option2 !== "") { optionData.push({ id: index+1, name: item.option2 }) }
                if (item.option3 !== "") { optionData.push({ id: index+2, name: item.option3 }) }
                if (item.option4 !== "") { optionData.push({ id: index+3, name: item.option4 }) }

				let quesObj = {
					id: index,
					question: item.question,
					status: item.status,
					symptom: item.symptom,
					questions:
						// { name: item.option1},
						// { name: item.option2},
						// { name: item.option3},
						// { name: item.option4}
						optionData

				};
				mainArr.push(quesObj);

			})
			console.log('arr', mainArr)
			setData(mainArr);

		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}
	
	const actionBodyTemplate = (rowData) => {
		return (
			<React.Fragment>
				<Button  icon="pi pi-pencil" title='Edit' rounded outlined className="mr-2 editBtnFocus" onClick={() => {
					setSelectedRowItem(rowData)
					setShowEditMode(true)
				}} />
				<Button  icon="pi pi-trash" title='Delete' rounded outlined className="deleteBtnFocus" onClick={() => deleteQuestConfirm(rowData.id)} />
			</React.Fragment>
		);
	};

	const optionBodyTemplate = (rowData) => {
		setQuesData(rowData.questions);
		return <Dropdown value={""} onChange={(e) => setSelectedCities(e.value)} options={rowData.questions} optionLabel="name"
			placeholder="Select Options" maxSelectedLabels={3} className="w-full md:w-20rem" />;
	};

	const statusBodyTemplate = (data) => {
		return <Tag value={data.status} severity={getSeverity(data.status)}></Tag>;
	};

	const deleteQuestConfirm = (id) => {
		confirmDialog({
			message: 'Are you sure you want to delete this Question?',
			header: 'Confirmation',
			accept: () => deleteQuestion(id),
		});
	}

	const deleteQuestion = async (Id) => {
		try {
			const token = localStorage.getItem('accessToken');
			let url = `${apiEndPoint}/symptoms/delete-question/${Id}`;
			let options = {
				method: 'DELETE',
				headers: {
					'Authorization': `Bearer ${token}`,
					'Content-Type': 'application/json'
				},
			}
			await fetch(url, options)
				.then(response => response.json())
				.then(actualData => {
					console.log('actualData ', actualData);
					toast.success("Question Delete Successfully.");
				})
				.catch(err => {
					console.log(err.message);
				});
		}
		catch (e) {
			console.log(e)
		}
	}

	const questPopUpClose = () => {
        setShowAddMode(false);
    }
	
	return (
		<>
			<div className='dashboard'>
				<Sidebar isActive={isActive} />
				<div className='dashboardContainer'>
					<Navbar isActive={isActive} handleChange={handleChange} />
					<div className='users-page'>
						<div className='container'>
							<div className='users-list'>
								<div className='addNewUser'>
									<div className='header-users'>
										<h6>Questions</h6>
									</div>
									<button className='addUserCls' title='Add User' onClick={() => setShowAddMode(true)}>
										<i className='pi pi-plus' style={{ paddingRight: '6px' }}></i> Create
									</button>
								</div>
								<DataTable value={data} paginator rows={10}>
									<Column style={{ width: '45%' }} field="question" header="Question" sortable filter></Column>
									<Column style={{ width: '15%' }} field="symptom" header="Symptom" sortable filter></Column>
									<Column field="options" header="Options" sortable filter body={optionBodyTemplate} style={{ width: '15%' }}></Column>
									<Column header="Status" body={statusBodyTemplate} sortable filter style={{ width: '15%' }}></Column>
									<Column header="Actions" body={actionBodyTemplate} style={{ width: '15%' }}></Column>
								</DataTable>
							</div>
						</div>
						<Dialog header="Add Question"
                            visible={showAddMode}
                            style={{ width: '39vw' }}
                            onHide={() => setShowAddMode(false)}
                        >

                            {<CreateQuestion questInfo={quesData} setGetReloadData={setGetReloadData} handleClose={questPopUpClose} />}
                        </Dialog>
						
						<Dialog header="Edit Question"
                            visible={showEditMode}
                            style={{ width: '39vw' }}
                            onHide={() => setShowEditMode(false)}>

                            <UpdateQuestion visible={showEditMode} setGetReloadData={setGetReloadData} updateData={selectedRowItem} setUserEdited={() => {
                                setShowEditMode(false);
                            }} />
                        </Dialog>

						<ConfirmDialog />

					</div>
				</div>
			</div>

		</>
	)
}

export default QuestionsList;